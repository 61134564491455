.song-player {
  text-align: center;
  padding: 3px;
  width: 100%;
  align-items: center;
  display: flex;
  gap: 10px;
  flex-direction: column;
  border-radius: 14px;
  justify-content: center;
  background: linear-gradient(135deg, #3e2260, #000000, #19154b);
  color: #fff;
}
.song-pattern-dropdown {
  background: linear-gradient(to right, #d1d1ffdc, #ff11c3);
  color: #030303;
  margin-right: 15px;
  padding: 3px 0;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  width: 75%;
  /* max-width: 179px; */
  height: min-content;
}
.song-pattern-option {
  background-color: #b4d8ff;
}
.song-player-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.song-head1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12;
  align-items: center;
  justify-content: space-around;
}
.pattern-dropdown1 {
  padding: 10px 0;
  border: 1px solid #000000;
  border-radius: 10px;
  background-color: #000000;
  color: aliceblue;
  font-size: 16px;
  /* width: 192px; */
}
.p-sel-container {
  width: 96%;
  display: flex;
  flex-direction: row;
  left: 12;
  align-items: center;
  margin-right: 13px;
  overflow-x: scroll;
}
.pattern-selectors {
  display: flex;

  flex-direction: row;
}
.pattern-dropdown1.active-pattern {
  /* border: 5px solid #2d91fc; Blue border for active pattern */
  background-color: #b80c0c;
}
.add-instrument-button2 {
  right: 7px;
  position: absolute;
  background: none;
  border: none;
  color: #0ff;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s; /* background-color: hsl(71, 96%, 42%); */
  background: none;

  width: 27px;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}
.add-instrument-button2:hover {
  color: #ff0;
  transform: scale(1.2);
}
.play-button-insplayer {
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-button-insplayer:focus {
  outline: none;
}

.led-container {
  display: flex;
  gap: 5px;
}

.led {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
}

.led-on {
  background-color: #007bff;
}

.playing {
  /* border: 2px solid #8601cd; */
  /* Red border when playing */
  background: linear-gradient(135deg, #6e0540, #000000, #4d0719);
}
.instrument-player h3 {
  color: rgb(255, 198, 55);
  text-shadow: 0 0 8px rgb(58, 255, 143);
}
.slider-container {
  /* margin-top: 10px; */
}

.dropdown-width-slider {
  width: 30px;
}
.led-n-slider {
  display: flex;
  align-items: center;
  margin-top: 0;
  width: 90%;
  justify-content: space-between;
}
