.payment-fail-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 30px 0;
  /* margin-top: 200px; */
  align-items: center;
  background: linear-gradient(123deg, #061d2a, #5e2d5f);
  color: #ffffff;
  justify-content: center;
  border: 1px solid #f5c6cb;
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  border-radius: 4px;
}

.payment-fail-container h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.payment-fail-container p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.retry-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 4px;
  cursor: pointer;
}

.retry-button:hover {
  background-color: #d32f2f;
}
