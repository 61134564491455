.your-products-container {
  /* max-width: 1200px; */
  width: 95%;
  margin: 0 auto;
  margin-top: -10px;
  /* padding: 20px; */

  color: #fff;
  font-family: "Arial", sans-serif;
}
.your-prod-title {
  display: flex;
  width: 100%;
  padding: 10px 0;
  align-items: center;
  justify-content: space-evenly;
}
.products-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Adjust as needed */
}
.yourprod-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 15px; /* Space between elements */
  margin-bottom: 10px;
  border-radius: 8px;
  background: linear-gradient(
    135deg,
    #0c404a,
    #1a2779,
    #44086f
  ); /* Green gradient */
  color: rgb(255, 255, 255);
  font-weight: bold;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.yourprod-header h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #ffffff;
}

.yourprod-header select,
.yourprod-header input {
  padding: 8px 12px;
  border: none;
  width: 150px;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
}

.yourprod-header select {
  background: #0f0c0c;
  color: #fcebeb;
  cursor: pointer;
}

.yourprod-header input {
  background: #000000;
  color: #fff01d;
  border: 1px solid #ccc;
  height: 25px;
}

.yourprod-header button {
  padding: 10px 4px;
  background: linear-gradient(
    15deg,
    #3a993c,
    #124225,
    #37c439
  ); /* Deep orange */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.yourprod-header button:hover {
  background: #e64a19; /* Darker orange */
}

.product-image-container {
  position: relative;
}
.yp-beat-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: radial-gradient(circle, #1020276b, #01030286);
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(26, 188, 156, 0.5);
  transition: transform 0.2s ease, background 0.3s ease, box-shadow 0.2s ease;
}

.yp-beat-play-button:hover {
  transform: translate(-50%, -50%) scale(1.2);
  background: radial-gradient(circle, #1abc9c, #16a085);
  box-shadow: 0 0 20px #000000;
}

.product-item {
  display: flex;
  flex-direction: column;

  align-items: center;

  height: min-content;
  justify-content: space-around;
  background: linear-gradient(128deg, #13354ee4, #0c052f86);
  margin-bottom: 20px;
  padding: 16px;
  font-size: 10px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.5);
  transition: transform 0.6s;
  max-width: 206px;
  min-width: 160px;
  /* Flex-grow, flex-shrink, flex-basis */
}
.product-item:hover {
  transform: scale(1.05);
}
.yourprod-input {
  display: flex;
  flex-direction: column;
}

.yourprod-details {
  text-transform: initial;
}
.product-image {
  width: 100%;
  height: 120px;
  object-fit: contain;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
}

.edit-button,
.delete-button,
.save-button {
  background-color: #00ff00;
  color: #000;
  border: none;
  /* display: flex; */
  align-items: center;
  padding: 4px 4px;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
  margin: 3px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
  transition: background-color 0.3s;
}

.edit-button:hover,
.delete-button:hover,
.save-button:hover {
  background-color: #ab0000;
  color: #fff;
}

.edit-button:disabled,
.delete-button:disabled,
.save-button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

input {
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #555;
  align-items: center;
  font-size: 10px;
  /* height: 20px; */
  margin-bottom: 5px;
  border-radius: 5px;
  width: 100%; /* Make input full width */
  box-sizing: border-box; /* Include padding in width */
}
.sold-button {
  /* padding: 10px 4px; */
  background: linear-gradient(
    15deg,
    #99563a,
    #0f27d9,
    #c43777
  ); /* Deep orange */
  color: white;
  border: none;
  border-radius: 5px;
  height: 30px;

  /* display: flex; */
  margin-top: 44px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.sold-button:hover {
  background: linear-gradient(45deg, #ff4b2b, #ff416c);
  transform: scale(1.05);
  box-shadow: 0 0 15px #ff4b2b;
}

.tutorial-yp {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.tutorial-yp > p {
  margin-right: 10px;
}
@media screen and (max-width: 568px) {
  .your-prod-title {
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .sold-button {
    margin-top: 5px;
    width: 140px;

    /* margin: 0 auto; */
  }
}
