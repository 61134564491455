.separate-beat-container {
  width: 80%;
  max-width: 600px;
  margin: 0px auto;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  background: linear-gradient(126deg, #1462d6, #030408, #0e2493);
  color: #ffffff;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.seperate-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  padding-top: 20px;
  justify-content: center;
}
.seperate-title > h1 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  text-shadow: 2px 2px 2px rgb(0, 0, 0), 3px 3px 3px rgba(0, 0, 0, 0.6);
  color: #ce2eff; /* Vibrant pink shade for a musical vibe */
  text-align: center; /* Centers the headline */

  /* Adds space around the headline for focus */
  text-transform: uppercase; /* Optional: makes the text uppercase for emphasis */
  letter-spacing: 1.5px; /* Increases spacing between letters for readability */
}
.beat-image-container {
  position: relative;
  margin: 10px 0;
}
.beatinfo-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0 30px;
  margin-bottom: 0;
  align-items: center;
}
.beatinfo-details > p {
  display: flex;
  margin: 5px auto;
  font-size: 11px;
}
.beatinfo-details > p > label {
  margin-right: 10px;
  font-size: 11px;
  color: #b4304d;
}
.beat-image-container img {
  width: 30%;
  border-radius: 8px;
}

.addCart-button-indv {
  display: flex;
  width: fit-content;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  background: radial-gradient(circle, #1b0f5e, #4f47f7);
  color: rgb(255, 255, 255);
  border: none;
  font-size: 12px;
  padding: 13px 12px;
  border-radius: 5px;
  font-weight: 376;
  margin-top: 12px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s;
}

.addCart-button-indv:hover {
  background: radial-gradient(circle, #430606, #8a0c4f);
}

.addCart-button-indv:disabled {
  background: radial-gradient(circle, #393939, #747474);
  cursor: not-allowed;
}
