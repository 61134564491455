/* Container Styling */
.sold-prod-container {
  max-width: 940px;
  width: 95%;
  margin: 0 auto;
  margin-top: -10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
  font-family: "Arial", sans-serif;
  color: #e0e0e0;
  overflow-x: hidden;
}

/* Title Section */
.sold-prod-title {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(135deg, #000000, #000000);
  color: #ffffff;
  padding: 10px 0;
  border-radius: 10px 10px 0 0;
  box-shadow: inset 0 -3px 5px rgba(0, 0, 0, 0.7);
  text-shadow: 1px 1px 3px #00ffff;
}

/* Title Text */

/* Transaction Info Section */
.transaction-info {
  background-color: #0b0b0b;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 2px 6px rgba(0, 255, 255, 0.2);
  border: 1px solid #00ffff;
}

.transaction-info p {
  margin: 5px 0;
  color: #e0e0e0;
  font-size: 18px;
}

.transaction-info strong {
  color: #42d0e3;
  text-shadow: 1px 1px 2px #3d4343;
}

/* Table Styling */
.sold-prod-table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
  background-color: #171717;
  color: #ffffff;
  font-size: 16px;
  overflow-x: auto;
}

.sold-prod-table th,
.sold-prod-table td {
  border: 1px solid #444;
  padding: 6px 4px;
  text-align: center;
}

.sold-prod-table th {
  background-color: #000000;
  color: #00d4aa;
  text-shadow: 1px 1px 2px #1c2525;
}

/* Individual Product Card */
.sold-prod-items {
  border: 1px solid #332d2d4d;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 20px;
  background: linear-gradient(145deg, #250529, #2d0b7d);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.8);
  transition: transform 0.3s;
}

.sold-prod-items:hover {
  transform: scale(1.01);
}

/* Button Styling */
.sold-button {
  background-color: #00bcd4;
  color: #ffffff;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.transaction-info {
  display: flex;
  flex-wrap: wrap;
}
.transaction-info > p {
  padding: 0 9px;
}
.sold-button:hover {
  background-color: #008c9e;
}
.monthly-summary {
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, #ff7378, #f7bdae, #fad0c4, #ff55cf);
  padding: 0 0px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  margin-bottom: 12px;
  text-align: center;
  color: #000000;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.monthly-summary:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.7);
}

/* Label Styling */
.monthly-summary label {
  font-weight: bold;
  font-size: 14px;
  color: #03010a;
  /* margin-right: 10px; */
  width: auto;
  align-items: center;
}
.month-sold-header {
  display: flex;
  align-items: center;
  padding: 4px 0px;
  margin: 0 auto;

  width: 100%;

  justify-content: center;
  flex-direction: row;
}
/* Dropdown Select Styling */
#month-select {
  padding: 8px 10px;
  border-radius: 8px;
  border: none;
  background: linear-gradient(135deg, #0f5060, #c24321, #611d4e);
  color: #ffffff;
  font-weight: bold;
  outline: none;
  margin-left: 12px;
  cursor: pointer;
  min-width: fit-content;
  transition: background 0.3s ease;
}

#month-select:hover {
  background: #4a059e;
}

/* Earnings Text Styling */
.monthly-summary h3 {
  font-size: 17px;
  font-weight: bold;
  color: #000000;
  margin-top: 15px;
  text-shadow: 1px 1px 4px rgba(255, 255, 255, 0.7);
}
/* Responsive Design */
@media (max-width: 568px) {
  .sold-prod-container {
  }

  .sold-button {
    padding: 8px 19px;
  }

  .sold-prod-table {
    display: block;
    overflow-x: auto;
    font-size: 9px;
    padding: 0;
    white-space: nowrap;
  }
  .transaction-info {
    padding: 0;
  }
  .transaction-info p {
    font-size: 12px;
  }

  .monthly-summary {
    padding: 0 12px;
  }

  .monthly-summary h3 {
    font-size: 13px;
  }
  #month-select {
    padding: 3px;
  }
}
