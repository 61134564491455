.instrument-dropdown,
.roll-pattern-dropdown,
.instrument-pattern-dropdown {
  background: linear-gradient(to right, #de76fb, #3cffd8);
  color: #000000;
  margin-left: 5px;
  padding: 3px 0;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  width: min-content;
  max-width: 79px;
  height: min-content;
}

.roll-pattern-dropdown {
  width: 33%;
  min-width: 100px;
  max-width: 134px;
}
.instrument-pattern-dropdown {
  width: 42%;
  max-width: 234px;
}
.playback-controls-ins {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 40%;
}
.instru-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: rgba(112, 218, 226, 0.985);
  text-shadow: 0 0 10px rgb(0, 251, 255);
  font-size: 14px;
  margin-top: 0px;
}

.sel-ins-p1 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0px;
  margin-top: 10px;
  width: 100%;
}
.sel-ins-p2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px;
  width: 100%;
}

.sel-ins-p2 input {
  width: 24%;
  padding: 8px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
}

.add-instrument-button,
.save-pattern-button {
  background-color: #00ff00;
  color: #000;
  padding: 3px;
  height: 26px;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  margin-left: 5px;
  transition: background-color 0.3s ease;
}

.add-instrument-button:hover,
.save-pattern-button:hover,
.delete-pattern-button:hover {
  background-color: #ff00ff;
}
.selected-instruments {
  margin-top: 0px;
  width: 100%;
  display: flex;
  height: min-content;
  flex-direction: column;
}

.selected-instrument {
  display: flex;
  flex-direction: row;
  width: 98%;
  justify-content: space-between;
  align-items: center;
  background: #1a1a1a;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px #0ff;
}

.instrument-name {
  width: 30%;
  display: flex;
  justify-content: center;
}

.instrument-name span {
  display: flex;
  font-size: 20px;
  color: #0ff;
  text-shadow: 0 0 5px #0ff;
  text-transform: uppercase;
}

.make-melody-button-container {
  width: max-content;
  justify-content: flex-start;
  align-items: center;
}
.piano-roll-button-container {
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.make-melody-button {
  background-color: #6a00ff;
  color: #ffffff;
  padding: 3px 18px;
  font-size: 18px;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  margin: 0 12px;
  transition: background-color 0.3s ease;
}

.piano-roll-button {
  background-color: #46ee13;
  color: #000000;
  padding: 5px;
  font-weight: 566;
  width: fit-content;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  margin-left: 12px;
  transition: background-color 0.3s ease;
}

.make-melody-button:hover {
  background-color: #e27d7d;
  color: #030202;
  font-weight: 600;
}

.piano-roll-button:hover {
  background-color: #ff0000;
  color: #fff;
}

.volume-slider {
}

.remove-button-container {
  display: flex;
  justify-content: center;
  margin-left: 30px;
}

.remove-instrument-button {
  background-color: #ff0000;
  color: #000;
  padding: 0px;
  font-size: 19px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.remove-instrument-button:hover {
  background-color: #ff00ff;
  color: #fff;
}

.preset-play-button {
  background-color: #ff00ff;
  color: #000;
  padding: 10px 10px;
  margin-left: 12px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 20px #ff00ff;
  transition: all 0.3s ease;
}
.eachins-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: center;
}
.preset-play-button:hover {
  background-color: #00ffff;
  color: #fff;
  box-shadow: 0 0 20px #00ffff;
}
@media screen and (max-width: 568px) {
  .drum-header {
    flex-direction: column;
    font-size: 20px;
  }

  .instrument-name span {
    font-size: 13px;
  }
  .eachins-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }
  .selected-instruments {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .selected-instrument {
    flex-direction: column;
    align-items: center;
    padding: 8px;
  }

  .make-melody-button-container,
  .piano-roll-button-container {
    margin: 10px 0;
  }
}
.playing-pattern-column {
  color: red;
}
