/* src/Header.css */
.header {
  background: linear-gradient(85deg, #000000, #000000, #16517c, #000000);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  /* transition: top 0.3s ease, padding 0.3s ease; */
}
.header-2 {
  justify-content: space-between;

  width: 100%;
}
.header-main {
  display: flex;
  top: 124px;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.App.scrolled .header-main {
  transform: translateY(-50px); /* Move the logo up */
  opacity: 0;
  top: 50px; /* Fade out the logo */
  transition: transform 0.4s ease, opacity 0.4s ease;
}
.App.scrolled .header {
  /* Adjust this value as necessary to hide the header */

  top: -120px;
  transition: top 0.4s ease;
}
.App.scrolled .cart-icon {
  transform: translateY(-50px); /* Move the cart icon up */
  opacity: 0; /* Fade out the cart icon */
  /* transition: transform 0.3s ease, opacity 0.3s ease; */
}
.header h1 {
  margin: 0;
  font-size: 24px;
}
.menu-icon1 {
  display: none;
  cursor: pointer;
  margin-right: 10px;
}
.logo-header {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  width: 92px;
  padding: 10px 0px;
  color: aliceblue;
  text-decoration: none;
}
.hxabyte-up {
  display: flex;
  margin-top: 0px;
  justify-content: center;
}
.hxabyte-down {
  display: flex;
}

.header div {
  display: flex;
  align-items: center;
}

.header div span {
  font-weight: bold;
}
.user-info {
  display: flex;
  flex-direction: row;
  margin: 0;
}
.user-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: linear-gradient(
    25deg,
    #1505bf,
    #d013ff
  ); /* Or any color you prefer */
  color: rgb(255, 254, 254);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  margin-right: 10px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.user-avatar:hover {
  box-shadow: 0 0 5px #346774, 0 0 15px #ffffff, 0 0 30px #000000,
    0 0 60px #3498db;
}

.button-logout {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.button-logout:hover {
  background-color: #d32f2f;
}

.dropdown-menu {
  position: absolute;
  top: 56px; /* Adjust based on your header height */
  right: 10px;
  font-style: italic;
  display: flex;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  flex-direction: column;
  background-color: white;
  border: 1px solid #8a8282;
  /* border-radius: 34px; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 33;
}

.dropdown-menu button {
  display: block;
  width: 100%;
  padding: 10px;
  text-transform: uppercase;
  background: linear-gradient(15deg, #000000, #0f535a);
  color: #ffffff;
  border: none;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-align: left;
  cursor: pointer;
  z-index: 2;
}

.dropdown-menu button:hover {
  background: #fefffe;
  /* font-weight: 677; */
  color: #000;
}
.dropdown-header {
  padding: 12px 28px;
  background-color: #000000; /* Or any color you prefer */
  color: white;
  font-weight: bold;
  font-size: 17px;
  text-align: center;
}
.user-cart-section {
  display: flex;
  align-items: center;
}

.cart-icon {
  display: flex;
  align-items: center;
  height: 20px;
  flex-direction: row;
  cursor: pointer;
  font-size: 20px;
  margin-right: 20px;
  transition: color 0.5s ease;
}
.cart-icon:hover {
  color: #ff8437;
}
.button-logout {
  padding: 8px 16px;
  background-color: #dc3545;
  color: white;
  border: none;
  margin-left: 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.button-logout:hover {
  background-color: #ffd900;
  color: #304c68;
}

.user-info {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
@media screen and (max-width: 568px) {
  .menu-icon1 {
    display: flex;
    margin-left: 14px;
    font-size: 20px;
    transition: background-color 0.5s ease;
  }
  .menu-icon1 :hover {
    background-color: rgb(165, 55, 255);
  }
  .logo-header {
    left: auto;
    width: 67px;
    justify-content: center;
  }
  .user-info {
    margin: 0;
  }
  .user-info span {
    font-size: 12px;
    margin-left: 6px;
  }
  .button-logout {
    margin-left: 20px;
    padding: 4px 8px;
    font-size: 10px;
  }
  .cart-icon {
    margin-right: 20px;
  }
  /* .header-2 > button {
    display: flex;
    flex-direction: row;
    margin: 0;
    align-items: center;
    background-color: #000000;
    width: min-content;
    justify-content: center;
  } */
}
