.chess-piece {
  font-size: calc(5vw); /* Responsive size based on viewport width */
  color: inherit; /* Inherit color from the parent element */
  text-shadow: inherit; /* Inherit text-shadow from the parent element */
  position: absolute; /* Absolute positioning to center within square */
  top: 50%; /* Move to the middle of the square */
  left: 50%; /* Move to the middle of the square */
  transform: translate(-50%, -50%); /* Center the piece exactly */
  display: flex;
  align-items: center;
  justify-content: center;
}

.white-piece {
  color: #ffffff; /* White pieces */
  text-shadow: 1px 1px 2px #000000; /* Slight shadow for better visibility */
}

.black-piece {
  color: #000000; /* Black pieces */
  text-shadow: 0px 1px 1px #ffffff;
}

@media (max-width: 600px) {
  .chess-piece {
    font-size: calc(7vw); /* Slightly larger on very small screens */
  }
}
