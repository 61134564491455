.document-list-container {
  display: flex;
  flex-direction: column;
  color: #eee;
  padding-top: 0px;
  width: 96%;
  margin: 0 auto;
}

.document-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10px 0;
}

.home-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* background: linear-gradient(135deg, #000000, #16314e, #000000); */
  align-items: center;
  margin-bottom: 10px;
  margin-top: 0px;
  padding: 4px;
}
.sorting-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}
.sorting-dropdown {
  padding: 5px;
  font-size: 13px;
  font-weight: 633;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 0px;
  border: none;
  width: 100px;
  border-radius: 5px;
  background: linear-gradient(to right, #17b4b4, #1680aa, #16aa65);
  color: rgb(18, 18, 18);
  cursor: pointer;
}

.sorting-dropdown:focus {
  outline: none;
}
.filter-dropdown {
  z-index: 7;
}
.product-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 150px;
  height: fit-content;
  min-height: 300px;
  background: linear-gradient(
    785deg,
    #061d3974,
    #4a0884,
    #000000
  ); /* Metallic gradient background */
  /* External and subtle internal shadow for depth */
  padding: 4px;
  margin: 0px auto;
  border-radius: 10px;
  justify-content: space-between;
  border: 1px solid #000000af;
  transition: transform 0.3s, box-shadow 0.3s, background 0.3s;
}

.product-row:hover {
  transform: scale(1.02);
  background: linear-gradient(
    215deg,
    #0f82b3,
    #000000,
    #1868a1
  ); /* Slightly lighter gradient on hover for interactive feel */
  /* Enhanced shadow for hover effect */
}
.beat-name-link {
  color: #40ffd9;
  transition: transform 0.3s;
}
.beat-name-link:hover {
  transform: scale(1.2);
}
.product-image-beats {
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
}

.product-image-beats img {
  width: 100%;
  height: auto;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  transition: transform 0.3s ease;
}

.product-image-beats:hover img {
  transform: scale(1.1);
}

.play-button-home {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: radial-gradient(circle, #0000008e, #01030286);
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(26, 188, 156, 0.5);
  transition: transform 0.2s ease, background 0.3s ease, box-shadow 0.2s ease;
}

.play-button-home:hover {
  transform: translate(-50%, -50%) scale(1.2);
  background: radial-gradient(circle, #1abc9c71, #16a085);
  box-shadow: 0 0 20px #000000;
}
.product-info {
  padding: 2px 0;
  text-align: center;
  font-size: 11px;
  color: rgb(124, 255, 172);
}
.product-genre {
  font-size: 10px;
  color: rgb(146, 146, 146);
}
.artist-beat-info,
.cost-cart-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* text-align: center; */
  text-transform: capitalize;
}

.product-info.artist-name {
  color: #ff62bb; /* Vibrant pink for artist name */
  font-size: 14px;
  margin-top: 0 auto;
  font-style: italic; /* Slightly larger font for emphasis */
}

.product-info.beat-name {
  color: #8be9fd; /* Bright blue for beat name */
  font-size: 15px; /* Slightly larger font for emphasis */

  margin-bottom: -10px;
}

.product-info.cost {
  color: #3cfffc; /* Bright green for cost */
  font-size: 11px; /* Slightly larger font for emphasis */
  margin-right: 4px;
  font-style: italic;
}

.addCart-button {
  display: flex;
  width: fit-content;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  background: radial-gradient(circle, #0e6490, #230699);
  color: rgb(255, 255, 255);
  border: none;
  font-size: 12px;
  padding: 8px 10px;
  border-radius: 5px;
  font-weight: 376;
  margin-top: 12px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s;
}

.addCart-button:hover {
  background: radial-gradient(circle, #430606, #8a0c4f);
}

.addCart-button:disabled {
  background: radial-gradient(circle, #393939, #747474);
  cursor: not-allowed;
}

@media (max-width: 568px) {
  .document-list-container {
    margin-top: -67px;
  }

  .banner img {
    padding-top: 6px;
  }

  .product-info.artist-name {
    /* Vibrant pink for artist name */
    font-size: 6px;
    font-style: italic;

    /* Slightly larger font for emphasis */
  }

  .product-info.beat-name {
    color: #8be9fd; /* Bright blue for beat name */
    font-size: 8px; /* Slightly larger font for emphasis */
    /* margin: 0 auto; */
  }

  .product-info.cost {
    /* Bright green for cost */
    font-size: 7px; /* Slightly larger font for emphasis */
  }
  .cartbtn-add {
    display: flex;
  }
  .product-row {
    flex-direction: row;
    max-width: 185px;
    min-height: fit-content;
    align-items: center;
    height: fit-content;
    justify-content: space-between;
  }

  .product-image-beats {
    background-color: #007bff00;
    width: 80px;
    align-items: center;
    min-height: 70px;
    max-height: 70px;
  }

  .artist-beat-info {
    height: 20;
    display: flex;
    /* background-color: rgb(48, 7, 7); */
    width: 40px; /* Added text alignment */
    flex: 0 0 46px;
    justify-content: center;
  }

  .cost-cart-info {
  }

  .addCart-button {
    color: white;
    display: flex;
    flex-direction: column;
    border: none;
    font-size: 5px;
    width: 40px;
    height: 30px;
    max-width: 70px;
    margin-top: 0px;
    justify-content: space-between;
    /* padding: 12px 11px; */
  }

  .product-info {
    font-size: 7px;
    text-align: left;

    padding: 0;
    text-transform: capitalize;
  }

  .play-button-home {
    position: absolute;
    top: 50%; /* Center vertically in the middle of the image */
    font-size: 14px;
    padding: 3px 4px;
    transform: translate(-50%, -50%); /* Center the button correctly */
  }

  .sorting-dropdown {
    font-size: 14px;
    padding: 5px;
    width: 92px;
    align-items: center;
  }

  .home-container {
    margin-top: 60px;
  }
  .home-container h2 {
    font-size: 16px;
  }
  .product-genre {
    font-size: 5px;
    text-align: left;
  }
}
