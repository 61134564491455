/* ProfileSettings.css */
.profile-settings-container {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  border-radius: 23px;
  background: linear-gradient(152deg, #2f127e, #1c1c1c, #421654, #0f3d42);
}

.profile-settings-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 45px;
  /* background-color: #ffffff; */
}

.phone-input {
  display: flex;
  gap: 10px;
}
.form-country {
  width: 323px;
  background-color: #e9ff90;
}
.form-group button {
  padding: 10px;
  background-color: #16ce50;
  color: #000000;
  border: none;
  border-radius: 45px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.phone-input-group {
  display: flex;
}
.form-group button:hover {
  background-color: #0056b3;
  color: #ffffff;
}

.message {
  margin-bottom: 20px;
  color: rgb(3, 205, 3);
  font-weight: 733;
}
