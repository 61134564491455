.cart-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.562);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.cart-modal-content {
  background: linear-gradient(15deg, #0103057f, #1c1c1c, #050410be, #3131317f);
  border-radius: 10px;
  padding: 20px;
  /* max-width: 700px; */
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 90%;
  justify-content: space-around;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.cart-modal-close {
  position: absolute;
  top: 13px;
  right: 35px;
  color: #ffffff;
  font-size: 48px; /* Increased */
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
}

.cart-modal-close:hover,
.cart-modal-close:focus {
  color: #ff5555;
  text-decoration: none;
  cursor: pointer;
}

.cart-modal-title {
  font-size: 2.5em; /* Increased */
  margin-bottom: 10px;
  margin-top: 0px;
  color: #ffffff;
  text-shadow: 0 0 5px #238090, 0 0 5px #bd93f9, 0 0 10px #8be9fd;
  text-align: center;
}

.cart-modal-scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;

  width: 100%;
}

.cart-section-title {
  font-size: 2em; /* Increased */
  margin-top: 20px;
  margin-bottom: 10px;
  color: #ff879f;
  text-shadow: 0 0 5px #0e090c, 0 0 5px #601dbd, 0 0 10px #fd8bd1;
}

.cart-modal-list {
  list-style: none;
  padding: 0;

  margin: 0;
}

.cart-modal-item {
  display: flex;
  height: 34px;
  justify-content: space-around;
  align-items: center;
  margin: 10px auto;
  width: 95%;
  padding: 12px; /* Increased */
  background: linear-gradient(135deg, #535050, #000000);
  border-radius: 5px;
  color: #f8f8f2;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s, box-shadow 0.3s;
}

.cart-modal-item:hover {
  transform: scale(1.02);

  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.item-details {
  display: flex;
  align-items: center;
  /* flex: ; */
}

.cart-item-name {
  margin-right: 0px;
  font-size: 14px; /* Increased */
  width: 250px;
  /* font-style: italic; */
  text-transform: uppercase;
  text-align: left;
  /* flex: 6; */
}

.cart-item-image {
  width: 40px; /* Increased */
  height: auto; /* Increased */
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
}

.quantity-input {
  width: 72px; /* Increased */
  height: 38px; /* Increased */
  text-align: center;
  align-items: center;
  margin-top: 6px;
  margin-left: 4px;
  border: 1px solid #110d0b;
  border-radius: 5px;
  background-color: #88ffc7;
  color: #282a36;
  font-size: 21px; /* Increased */
  font-weight: 777;
  padding: 4px; /* Increased */
}

.quantity-input:focus {
  outline: none;
  border-color: #ff5555;
}

.cart-item-cost {
  flex: auto;
  margin-right: 10px;
  color: #6eff93;
  font-size: 20px; /* Increased */
  text-align: right;
}

.cart-modal-remove {
  background-color: #ff8c8c;
  border: none;
  color: #000000;
  padding: 6px 6px; /* Increased */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cart-modal-remove:hover {
  background-color: #ff4444;
  color: #daf9e2;
}

.modalfooter {
  margin-top: 20px;
  text-align: center;
}

.total-cart {
  font-size: 1.5em; /* Increased */
  margin-bottom: 20px;
  color: #89ffb2;
}

.proceed-button button {
  background-color: #ffee00;
  border: none;
  color: #282a36;
  padding: 12px 22px; /* Increased */
  border-radius: 5px;
  font-size: 1.2em; /* Increased */
  font-weight: 677;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.proceed-button button:hover {
  background-color: #4c0f9b;
  color: #ffcdcd;
}

.cart-item-cost span {
  display: inline-block;
  width: 35px; /* Increased */
  text-align: center;
}
.fashion-counter1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px; /* Adjust as needed */
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  padding: 2px;
  /* margin-top: 10px; */
}
/* Apply same styles for max-width 458px */
@media screen and (max-width: 558px) {
  .cart-modal-close {
    font-size: 38px;
  }

  .cart-modal-title {
    font-size: 1.5em;
  }

  .cart-section-title {
    font-size: 1em;
  }
  .cart-modal-content {
    width: 100%;
    /* height: 200px; */
  }
  .cart-modal-item {
    padding: 2px;
    align-items: center;
    width: 100%;
    /* margin-left: 7px; */
  }
  .cart-modal-item:hover {
    transform: scale(1);
    /* margin-left: 7px; */
  }
  .cart-item-name {
    font-size: 9px;
    width: 36%;
    margin: 0 10px 0 0;
  }

  .cart-item-image {
    width: 30px;
    height: 30px;
  }

  .cart-item-cost {
    font-size: 11px;
    width: max-content;
  }

  .cart-modal-remove {
    padding: 3px 3px;
  }

  .total-cart {
    font-size: 1.1em;
  }

  .proceed-button button {
    padding: 8px 18px;
    font-size: 1.2em;
  }

  .cart-item-cost button {
    padding: 0 12px;
    margin: 0 6px;
    font-size: 1.2em;
  }

  .cart-item-cost span {
    width: 20%;
  }
}
