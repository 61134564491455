/* Main board grid layout */
.board {
  display: grid;
  grid-template-columns: repeat(8, 1fr); /* Make each column take equal space */
  grid-template-rows: repeat(8, 1fr); /* Make each row take equal space */
  border: 2px solid #000000;
  margin: 0 auto;
  width: 45%; /* Board width is 45% of the container */
  max-width: 100vw; /* Maximum width is the viewport width */
  height: auto; /* Height will adjust to keep the aspect ratio */
  padding: 0; /* Padding for both left and right */
  box-sizing: border-box; /* Ensure padding does not affect total width */
}

/* Inverted board for the other player */
.invert-board {
  transform: rotate(180deg); /* Rotate the entire board */
}

.invert-board .square {
  transform: rotate(
    180deg
  ); /* Rotate each square back to its original orientation */
}
.moves-list {
  max-height: 200px;
  overflow-y: auto;
  background: #f8f8f8;
  border: 1px solid #ccc;
  margin-top: 20px;
}
/* Chess volume slider container */
.chess-volume-slider {
  width: 222px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
}

/* Responsive board styling for smaller screens */
@media (max-width: 600px) {
  .board {
    padding: 0 5px;
    width: 99%; /* Adjust width for smaller screens */
  }
}

/* Optional square styling, if you want to style the squares individually */
.square {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
}

/* Highlighted squares for legal moves */
.square.highlight {
  background-color: rgba(0, 255, 0, 0.3); /* Green highlight */
}

.square.inCheck {
  background-color: rgba(255, 0, 0, 0.3); /* Red highlight for check */
}

/* Player info and status styles */
.player-info {
  text-align: center;
  font-size: 1.2em;
  margin: 10px 0;
}

/* Restart button styling */
.restart-button {
  display: block;
  margin: 15px auto;
  padding: 10px 20px;
  background-color: #f56b2a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}

.restart-button:hover {
  background-color: #e64a19;
}

/* Promotion modal related styling, if needed */
