.offers-table-container {
  background-color: #121212; /* Dark background for the container */
  color: #fff; /* White text color */
  padding: 20px; /* Some padding around the content */
  border-radius: 8px; /* Rounded corners */
  width: 80%; /* Container width */
  margin: auto; /* Centering the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.offers-table-container h2 {
  text-align: center; /* Centering the header */
  color: #ff8c00; /* A bright color for the header */
}

.offers-table {
  width: 100%; /* Full-width table */
  border-collapse: collapse; /* Ensures borders between cells are merged */
  margin-top: 20px; /* Space above the table */
}

.offers-table th,
.offers-table td {
  text-align: center; /* Left align text in cells */
  padding: 12px; /* Padding within cells */
  border-bottom: 1px solid #666; /* Dark border for the bottom of the cells */
}

.offer-header-row th {
  background-color: #1a1a1a; /* Slightly lighter dark background for headers */
}

.offer-row td {
  background-color: #262626; /* Dark cells */
  color: #bada55; /* Stylish green text for the data */
}

.offer-row:hover {
  background-color: #333; /* Dark hover effect */
  cursor: pointer; /* Pointer cursor on hover to indicate interactivity */
}
