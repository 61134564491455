.beat-maker {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;

  padding-top: 0px; /* Dark background to complement neon colors */
}
.beatmaker-loader {
  margin-top: 72px;
}
.bmaker-header {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
}
.add-sound {
  padding: 10px 10px;
  /* margin: 20px auto; */
  display: block; /* Makes the button take a full-width block style */
  font-size: 10px; /* Slightly larger font size for better readability */
  color: #00c8ff; /* Neon blue */
  background-color: #000;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 200, 255, 0.75), 0 0 25px rgba(0, 200, 255, 0.5),
    0 0 50px rgba(0, 200, 255, 0.25); /* More intense neon glow */
}

.add-sound:hover,
.add-sound:focus {
  transform: translateY(-5px); /* Slight lift effect on hover/focus */
  box-shadow: 0 0 20px rgba(0, 200, 255, 1), 0 0 35px rgba(0, 200, 255, 0.75),
    0 0 70px rgba(0, 200, 255, 0.5); /* Enhanced neon glow on hover/focus */
}
.beatmaker-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 4px;
}
.pads {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;

  margin-top: 10px;
  margin-bottom: 10px;
}

.pad {
  padding: 13px 17px;
  border: none;

  color: #8cffa0; /* Bright neon green */
  background: linear-gradient(
    135deg,
    #6a0a48,
    #000000,
    #52430e
  ); /* Dark background for contrast */
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(140, 255, 160, 0.75),
    0 0 15px rgba(140, 255, 160, 0.5), 0 0 30px rgba(140, 255, 160, 0.25); /* Neon glow effect */
}
.map-button {
  padding: 10px 10px;
  display: block;
  font-size: 10px;
  color: #ffe08b; /* Neon blue */
  background-color: #000;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 0 10px rgba(255, 149, 0, 0.75), 0 0 25px rgba(255, 64, 0, 0.5),
    0 0 50px rgba(255, 196, 0, 0.25); /* Neon glow */
}

.map-button:hover,
.map-button:focus {
  /* Slight lift effect on hover/focus */
  box-shadow: 0 0 20px rgb(255, 85, 0), 0 0 35px rgba(191, 0, 255, 0.75),
    0 0 70px rgba(255, 47, 0, 0.823); /* Enhanced neon glow on hover/focus */
}
.pad:hover {
  box-shadow: 0 0 10px rgba(140, 255, 160, 1),
    0 0 20px rgba(140, 255, 160, 0.75), 0 0 40px rgba(140, 255, 160, 0.5); /* Enhanced neon glow on hover */
}

.error {
  color: #ff3860; /* Bright red for errors */
}

.loading {
  color: #fff; /* White text for loading message */
}
.led-indicator {
  display: flex;
  gap: 5px;
}

.led-step {
  width: 6px;
  height: 6px;
  margin: 2px;
  background-color: grey;
  display: inline-block;
  border-radius: 50%;
}

.led-step.active {
  background-color: rgb(30, 255, 0);
}

.led-step.special-step {
  background-color: rgb(207, 6, 6);
}

.record-button4 {
  background: none;
  display: flex;
  font-size: 15px;
  align-items: center;
  border-radius: 14%;
  background-color: #e40101;
  height: 20px;
  border: none;
  cursor: pointer;
}
.record-button4 > p {
  color: #fff;
  font-size: 10px;
  margin-left: 4px;
}
.record-button4:hover {
  background-color: #c619ff;
}
.loop-buttons {
  display: flex;
  gap: 5px;
  margin-left: 10px;
}

.loop-button {
  padding: 5px;
  font-size: 10px;
  background-color: grey;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.loop-button.active {
  background-color: blue;
}
