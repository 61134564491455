.sell-merch-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(142deg, #0f2542, #170c35, #000000ac, #0f3d42);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sell-merch-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  background-color: #423f49;
  color: aliceblue;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group textarea {
  resize: vertical;
}

.form-group input[type="checkbox"] {
  display: inline-block;
  width: auto;
  margin-right: 10px;
}
