/* Styling for the Releases component */
.releases-container {
  background: linear-gradient(
    135deg,
    #000000,
    #283747,
    #000000
  ); /* Dark background color */
  color: #fff; /* Light text color */
  width: 100%;
  align-items: center;
  text-align: center; /* Center the text */
  margin: 0 auto; /* Center the container horizontally */
}

.video-preview {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.video-thumbnail-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 500px; /* Ensures the thumbnail does not exceed the screen width */
}

.video-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.play-button-rel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  color: #0ff; /* Neon color */
  text-shadow: 0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #0ff; /* Neon glow effect */
  opacity: 0.8;
  pointer-events: none; /* Ensures the play button does not interfere with clicking the thumbnail */
}
