.song-player {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.selected-pattern-details {
  width: 80%;
}
.led-container {
  display: flex;
}
.pattern-details {
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.pattern-details h4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px 0;
}
.kit-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}
.kit-name {
  flex: 1;
}
.sequence-grid {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  gap: 2px;
  flex: 4;
  position: relative;
}
.sequence-grid div {
  text-align: center;
  transition: background-color 0.1s;
}
.kit-volume {
  flex: 0.5;
  text-align: right;
}
.sp-title {
  text-transform: uppercase;
  color: #ff0;
}
.play-indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 0, 0, 0.2);
  transition: left 0.1s;
}
.playing .play-indicator {
  left: 0;
  animation: move-indicator linear infinite;
}
@keyframes move-indicator {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}
.song-player-header {
  background-color: rgb(11, 18, 41);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.song-player-grid {
  margin-top: 13px;
  width: 100%;
  overflow: auto;
  height: 200px;
}
.song-player-grid table {
  width: 5000px; /* Fixed width for the table */
  border-collapse: collapse;
}
.song-player-grid th,
.song-player-grid td {
  width: 50px;
  height: 20px;
  border: 1px solid #ccc;
  text-align: center; /* Center align text */
}
.song-player-grid th,
.song-player-grid td:first-child {
  background-color: #0f2d1d; /* Optional: Highlight header and first column */
}
.song-player-grid th.sticky-top {
  position: sticky;
  top: 0; /* Stick to the top */
  z-index: 1; /* Ensure it stays above other cells */
}
.song-player-grid td.sticky-left {
  position: sticky;
  left: 0; /* Stick to the left */
  z-index: 1; /* Ensure it stays above other cells */
  background-color: #101625; /* Optional: Highlight header and first column */
}
.top-left-cell {
  z-index: 20; /* Set the z-index for the top-left-most cell */
  position: sticky;
  top: 0;
  left: 0;
  border: 1px solid #ccc;
  background-color: #f0f0f0; /* Optional: Highlight the top-left cell */
}
.song-player-grid td.pink-cell {
  background-color: rgb(87, 0, 39);
}
.song-player-grid td.violet-cell {
  background-color: rgb(64, 5, 67);
}
.song-player-grid td.pink-cell,
.song-player-grid td.violet-cell {
  cursor: pointer;
}
.song-player-cell {
  position: relative;
}
.dropdown.open {
  position: absolute;
  color: #c9b0b0;
  background-color: rgb(26, 13, 13);
  border: 1px solid #ccc;
  z-index: 1000;
  width: 100px;
  max-height: 150px;
  overflow-y: auto;
  padding: 5px;
}
.dropdown.open div {
  padding: 5px;
  cursor: pointer;
  color: rgb(252, 235, 235); /* Ensure text is visible */
}
.dropdown.open div:hover {
  background-color: #7d7575;
}
.highlighted-column {
  background-color: rgba(255, 255, 4, 0.834); /* Highlight color */
}
.active-bar {
  color: red; /* Change font color to red */
}
.marker {
  display: none;
}
