.sequencer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 99%;
  margin: 0;
  border-radius: 40px;
  justify-content: space-evenly;
  background: linear-gradient(155deg, #000000, #000000, #193e4e);
  color: #fff;
  font-family: "Arial", sans-serif;
}

.loading-icon {
  animation: spin 1s linear infinite;
}

.volume-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 7px;
  background-color: #000000;
  justify-content: center;
}

.volume-control label {
  font-weight: bold;
  align-items: center;
}

.volume-control input[type="range"] {
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.controls-seq {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 20px;
}

.sorting-dropdown2 {
  background: linear-gradient(to right, #ba9cda, #7ee28e);
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-weight: 444;
  font-size: 12px;
  margin-right: 12px;
  width: min-content;
  height: min-content;
}

.kit-volume-control {
  display: flex;
  align-items: center;
  margin-right: -7px;
}

.play-button-seq {
  background: rgb(52, 4, 4);
  border: 2px solid #08f;
  border-radius: 30%;
  color: rgb(76, 254, 117);
  padding: 5px 5px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px #08f, 0 0 20px #08f;
}

.play-button-seq:hover {
  color: rgb(255, 141, 18);
  box-shadow: 0 0 10px #ff8d12, 0 0 20px #ff8d12;
}

.tempo-control button {
  background: none;
  border: 2px solid #08f;
  border-radius: 50px;
  color: #0f0;
  padding: 5px 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tempo-control button:hover {
  color: rgb(255, 255, 8);
  box-shadow: 0 0 10px #ffff08, 0 0 20px #ffff08;
}

.tempo-control {
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
}

.tempo-control span {
  justify-content: center;
  margin: 0 4px;
}
.instrument-dropdown {
  background-color: #282a36;
  color: #f8f8f2;
  border: 1px solid #44475a;
  border-radius: 5px;
  padding: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.track1 {
  display: flex;
  align-items: center;
  height: min-content;
  margin-top: -10px;
  width: 100%;
  justify-content: space-between;

  /* Center the .track1 container */
}

.kit-name {
  flex: 0 0 50px;
  display: flex;
  text-align: left;
  font-size: 12px;
  width: 50px;
  cursor: pointer;
  text-transform: uppercase;
  color: rgb(0, 255, 30);
  background-color: #111;
  border: none;
  text-shadow: 0 0 5px #0f0;
}

.steps {
  display: flex;
  gap: 1px;
  overflow-x: auto;
  overflow-y: hidden;
  flex: 4;
}

.beat {
  width: 25px;
  height: 25px;
  border: 2px solid rgb(0, 38, 255);
  background-color: transparent;
  border-radius: 20%;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  position: relative;
  box-shadow: 0 0 2px rgba(0, 136, 255, 0.54), 0 0 10px rgba(0, 136, 255, 0.407);
}

.beat:nth-child(4n) {
  margin-right: 9px; /* Adds a space after every 4th beat */
}

.beat:hover {
  background-color: rgb(47, 176, 174);
  box-shadow: 0 0 10px rgb(47, 176, 174), 0 0 20px rgb(47, 176, 174);
}
.kit-name.active {
  display: flex;
  background-color: #4caf50; /* or any color to indicate active state */
  color: white;
}
.kit-name > button {
  width: 50%;
}
.beat.active {
  background-color: rgb(255, 22, 146);
  transform: scale(1.1);
  box-shadow: 0 0 4px rgb(255, 0, 0), 0 0 20px rgb(255, 0, 89);
}
.undo-button,
.redo-button {
  background-color: #1f1b1b; /* or any preferred color */
  color: rgb(203, 200, 108);
  border: none;
  cursor: pointer;
  border-radius: 13px;
  margin-left: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.undo-button:hover,
.redo-button:hover {
  background-color: rgb(108, 176, 203);
  color: #474040;
}
.beat.current {
  box-shadow: 0 0 12px rgb(66, 234, 58), 0 0 24px rgb(3, 242, 255);
}

.drum-header1 {
  display: flex;
  flex-direction: row;
  height: min-content;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin: 0;
}

.drum-header1 h3 {
  color: rgb(255, 187, 0);
  text-shadow: 0 0 8px rgb(130, 155, 22);
}

.volume-slider-seq {
  -webkit-appearance: none;
  appearance: none;
  width: 40px;
  height: 7px;
  background: rgb(133, 255, 255);
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  cursor: pointer;
}

.volume-slider-seq:hover {
  opacity: 1;
}

.volume-slider-seq::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 7px;
  height: 15px;
  background: #ff0;
  cursor: pointer;
}

.volume-slider-seq::-moz-range-thumb {
  width: 20px;
  height: 10px;
  background: #ff0;
  cursor: pointer;
}

.mute-button {
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 0px;
  color: rgb(0, 255, 8); /* Color for the mute icon */
  font-size: 1.2em;
}

.mute-button:hover {
  color: darkred; /* Darker color on hover */
}

.mute {
  color: rgb(200, 25, 25);
}

/* Media query for devices with max-width of 568px */
@media screen and (max-width: 668px) {
  .kit-name {
    font-size: 8px; /* Smaller font size */
    width: 10px;
    /* flex: 1; */
    margin-left: -8px;
    overflow-x: auto;
    align-items: center; /* Smaller fixed width */
  }

  .drum-header1 {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .steps {
    display: flex; /* Change from grid to flex for easier control over scroll */
    /* padding: 2px; */
    /* margin-top: -6px; */
    align-items: center;
    grid-template-columns: repeat(8, 1fr);
    overflow-x: auto;
    overflow-y: hidden;
    flex: 7; /* Added flex to take available space */
  }

  .beat {
    width: 20px;
    height: 21px; /* Ensure each beat has a consistent size */
    flex: 0 0 auto; /* Prevents flex items from growing or shrinking */
  }

  .mute-button {
    font-size: 15px;
  }

  .track1 {
    flex-direction: row; /* Stack elements vertically */
    align-items: center;
    width: 100%;
    justify-content: center; /* Center the .track1 container */
  }

  .controls-seq {
    flex-direction: row;
  }

  .tempo-control {
    flex-direction: row;
    align-items: center;
    width: min-content;
    margin-left: 12px;
    font-size: 10px;
  }

  .play-button-seq {
    padding: 7px;
  }

  .tempo-control button {
    padding: 2px; /* Smaller padding */
    font-size: 14px;
    flex: 0 0 20px; /* Smaller font size */
  }

  .sorting-group {
    display: flex;
    flex-direction: column;
  }
  .bpm-display {
    font-size: 10px;
  }
  .sorting-group > select {
    margin: 4px;
  }
}
