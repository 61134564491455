.App {
  text-align: center;
  background: linear-gradient(45deg, #111111, #00000092, #00000097, #000000);

  color: rgb(255, 255, 255);
  padding-top: 22px;
  margin-bottom: 83px;
  transition: padding-top 0.3s ease;
  min-height: 1300px;
}
.App.scrolled {
  padding-top: 12px; /* Adjust this value based on your header height */
}
body {
  font-family: Arial, sans-serif;

  background: black;
  /* You can choose a font family that suits your design */
}

.footer-content {
  width: 100%;
  text-align: left;
  background: black;
  margin-bottom: 10px;
}
/* .allrights {
  font-size: 17px;
  width: 100%;
  padding-top: 74px;
  text-align: center;
  background-color: rgb(0, 0, 0);
} */
/* styles.css */
.footer-hxabyte {
  bottom: 0;
  width: 100%;
  text-align: center;
  position: relative;
  align-items: center;
  padding-bottom: 40px;
}
.footer-copyright {
  color: #f7f0f0;
  width: 100%;
  text-align: center;
  justify-content: center;
  font-size: 10px;
}
.footer-nav {
  margin: 0;
  margin-top: 23px;

  color: aliceblue;
  display: flex;

  font-size: 10px;
  flex-direction: row;
  justify-content: space-evenly;
}
.footer-text {
  margin: 0;
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;

  color: rgb(187, 187, 187);
}
.footer-text-creator {
  color: rgb(126, 126, 126);
  display: flex;
  width: 100%;
  font-size: 18px;
  align-items: center;

  justify-content: center;
}

h2 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 25px;
  text-shadow: 2px 2px 2px rgb(0, 0, 0), 3px 3px 3px rgba(0, 0, 0, 0.6);
  color: #ff00aa; /* Vibrant pink shade for a musical vibe */
  text-align: center; /* Centers the headline */
  padding-top: 30px;
  margin-bottom: 0px; /* Adds space around the headline for focus */
  text-transform: uppercase; /* Optional: makes the text uppercase for emphasis */
  letter-spacing: 1.5px; /* Increases spacing between letters for readability */
}

.footer-text-creator > p {
  font-size: 11px;
  color: #948989;
  margin-right: 34px;
}
.footer-text-creator > a {
  font-size: 21px;
  margin-top: 12px;
  margin-right: 34px;
  color: rgba(0, 255, 255, 0.779);
}
@media screen and (max-width: 568px) {
  .App {
    margin-top: 33px;
    padding-top: 17px;
  }
  h2 {
    font-size: 20px; /* Adjusted font size for smaller screens */
    margin-top: 0px;
    padding-top: 6px;
    /* Reduced top margin for better spacing */
  }
  .footer-nav {
    font-size: 7px;
  }
  .footer-text {
    font-size: 6px;
  }
  .footer-text-creator > p {
    font-size: 7px;
    margin-right: 14px;
  }
  .footer-copyright {
    font-size: 8px;
  }
  .footer-text-creator > a {
    font-size: 11px;
    margin-right: 14px;
    color: aqua;
  }
}
