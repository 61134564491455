.melody-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.724);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 56;
}
.octave-button {
  background: none;
  border: none;
  color: white;
  font-size: 15px;
  cursor: pointer;
  margin: 0 0px;
}

.melody-modal-content {
  background: #111;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 20px #0ff, 0 0 30px #0ff, 0 0 40px #0ff;
  width: 95%;
  z-index: 45;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden; /* Hide vertical scrollbar */
}
.scroll-container {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-top: 10px; /* Adjust as needed */
  height: 20px; /* Adjust as needed for scrollbar visibility */
  background: #111;
  align-items: center;
}

.keyboard-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden; /* Hide vertical scrollbar */
  white-space: nowrap;
}

.keyboard {
  display: inline-block;
  white-space: nowrap;
}

.key-wrapper {
  display: inline-block;
  text-align: center;
}

.note-label {
  font-size: 10px;
  color: #0ff;
  margin-bottom: 2px;
}

.key {
  display: inline-block;
  width: 30px;
  height: 200px; /* Increased height */
  margin: 0px;
  cursor: pointer;
  user-select: none;
  transition: transform 0.1s, box-shadow 0.1s;
}

.white-key {
  background: #222;
  border: 1px solid #0ff;
  box-shadow: 0 0 10px #0ff;
  height: 200px;
}
.note-indicator {
  position: relative;
  font-size: 7px;
  text-align: center;
}
.black-key {
  background: #000;
  border: 1px solid #f0f;
  height: 100px; /* Reduced height for black keys */
  margin-left: -11px; /* Adjusted margin for the new width */
  margin-right: -11px; /* Adjusted margin for the new width */
  z-index: 1;
  position: relative;
  box-shadow: 0 0 10px #f0f;
  width: 23px; /* Decreased width by 7px */
}

.key:hover,
.key:active,
.key.active {
  transform: scale(1);
  box-shadow: 0 0 10px #ff0;
}

.key:active,
.key.active {
  background: #444;
}

.controls {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.volume-fader {
  margin-right: 10px;
}
.melody-modal-header {
  width: 91%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #000000;
  padding: 10px;
  border-radius: 10px 10px 0 0;
}
.melody-controls {
  display: flex;
  width: 99%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 5px;
}
.melody-modal-title {
  margin: 0;
  text-align: center;
  flex-grow: 1;
}
.close-button-melody {
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 5px;
  right: 12;
  padding: 5px;
  margin-left: 12px;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.close-button-melody:hover,
.close-button-melody:active {
  color: #d74e4e;
  background: #444;
  box-shadow: 0 0 20px #ff0;
}
.hold-button {
  left: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background: #222;
  border: 1px solid #0ff;
  color: #0ff;
  box-shadow: 0 0 10px #0ff;
  transition: background 0.3s, box-shadow 0.3s;
}

.hold-button.active {
  background: #444;
  box-shadow: 0 0 20px #ff0;
}
.melodymodal-controls {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  justify-content: space-evenly;
}
.stop-button {
  background-color: #c44dff; /* Red background color */
  color: white; /* White text color */
  border: none; /* No border */
  border-radius: 50%; /* Round button */
  width: 35px; /* Width of the button */
  height: 35px; /* Height of the button */
  /* Center vertically */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.stop-button:hover {
  background-color: #8d0000; /* Darker red on hover */
}

.stop-button:active {
  background-color: #3300cc; /* Even darker red when active (clicked) */
}

.stop-button svg {
  width: 20px; /* Width of the icon */
  height: 20px; /* Height of the icon */
}

.metronome-counter {
  font-size: 1.5em;
  margin-left: 10px;
  font-weight: bold;
}
.led-indicator {
  display: flex;
  gap: 2px;
  margin-left: 0px;
}

.led1 {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #03faac;
}

.led1.active {
  background-color: rgb(255, 0, 0); /* Green for active step */
}

.bpm-button2 {
  /* width: 20px; */
  font-size: 14px;
  height: 20px;
  background-color: none;
  border-radius: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 3px;
  margin: 7px;
}

.bpm-button2:hover {
  background-color: #a10707;
}

.bpm-value {
  /* width: 50px; */
  text-align: center;
  margin: 0;
}
.record-button {
  background-color: #d04242e9;
  font-size: 20px;
  height: 26px;
  border-radius: 20%;
  align-items: center;
  display: flex;
  border: none;
  cursor: pointer;
  margin-left: 0px;
}

.record-toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  text-align: center;
  width: min-content;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.record-toggle-button .icon {
  font-size: 20px;
}

.start-recording {
  background-color: #d71515;
  color: rgb(255, 253, 253);
}

.start-recording:hover {
  background-color: #e28c7e;
  color: #000;
}

.stop-recording {
  background-color: #dc3545;
  color: white;
}

.stop-recording:hover {
  background-color: #c82333;
}
.volume-slider-mel {
  -webkit-appearance: none;
  appearance: none;
  width: 80px;
  height: 12px;
  align-items: center;
  background: rgb(133, 255, 255);
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;

  cursor: pointer;
}

.volume-slider-mel:hover {
  opacity: 1;
}

.volume-slider-mel::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(255, 213, 27);
  cursor: pointer;
}

.volume-slider-mel::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #ff0;
  cursor: pointer;
}
.option-controls {
  margin-top: 12px;
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-evenly;
}
.highlighted {
  background-color: rgba(187, 255, 0, 0.799);
}
@media (max-width: 568px) {
  .melody-modal-content {
    padding: 10px;
  }

  .controls {
    flex-direction: column;
  }

  .volume-fader {
    margin-bottom: 10px;
  }
  .key {
    width: 25px;
    height: 180px; /* Increased height */
  }
  .white-key {
    height: 160px;
  }
  .black-key {
    height: 80px; /* Reduced height for black keys */

    width: 23px; /* Decreased width by 7px */
  }
}
