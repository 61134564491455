.mini-player-container {
  position: fixed;
  bottom: 0;
  left: 0;
  /* flex-wrap: wrap; */
  width: 100%;
  background: linear-gradient(144deg, #000000, #06141b, #061120, #000000);
  color: #ffffff;

  display: none;
  z-index: 100;
}

.mini-player-container.visible {
  display: block;
}

.waveform {
  width: 100%;
  height: 20px; /* Reduced height */
  margin-bottom: 2px;
}

.mini-player-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
}

.left-section {
  display: flex;
  align-items: center;
}

.album-cover {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 10px;
}

.track-info {
  display: flex;
  flex-direction: column;
  color: rgb(45, 220, 251);
  text-align: left;
}
.track-artist {
  font-size: 13px;
  margin-top: 12px;
  font-style: italic;
  color: #f94fff;
}
.center-section {
  display: flex;
  align-items: center;
}

.play-pause-btn,
.stop-btn {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
  margin-right: 1px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.play-pause-btn:hover,
.stop-btn:hover {
  color: #00eaff; /* Spotify green for hover effect */
  transform: scale(1.1); /* Slightly enlarge on hover */
}
.right-section {
  display: flex;
  color: #a368ec;
  align-items: center;
}

.volume-slider {
  margin-right: 10px;
  /* max-width: 100px; */
}

.control-btn {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
}

a {
  color: #ffffff;
  text-decoration: none;
  margin-right: 10px;
}
.info-btn {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
}
.info-btn :hover {
  color: #00eaff;
}
.info-message {
  position: absolute;
  background-color: #303030;
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  font-size: 12px;
  top: -50px; /* Adjust based on your layout */
  right: 5px; /* Adjust based on your layout */
  width: 300px;
  z-index: 101;
}

@media screen and (max-width: 568px) {
  .album-cover {
    width: 30px;
    height: 30px;
    object-fit: cover;
    margin-right: 10px;
  }
  .track-info {
    display: flex;
    font-size: 9px;
    flex-direction: column;
  }
  .volume-slider {
    margin-right: 10px;
    width: 60px;
  }
  .play-pause-btn,
  .stop-btn {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 17px;
    cursor: pointer;
    margin-right: 1px;
    transition: color 0.3s ease, transform 0.3s ease;
  }
}
