.contact-container {
  max-width: 605px;
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(145deg, #000000, #310909);
  border-radius: 10px;
  box-shadow: 12px 5px 15px #1c1818;
  color: #fffafa;
}

.contact-description {
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.form-control {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 96%;
  font-size: 16px;
  background: linear-gradient(145deg, #4e4040, #ebd3d3);
  box-shadow: inset 2px 0px 15px #155309, inset -2px -2px 5px #e31717;
}

.form-control:focus {
  outline: none;
  border-color: #6a11cb;
}

.btn-submit {
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(145deg, #6a11cb, #2575fc);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.btn-submit:hover {
  background: linear-gradient(145deg, #2575fc, #6a11cb);
}

.contact-info {
  margin-top: 20px;
}

.contact-info h2 {
  margin-bottom: 10px;
}

.contact-info p {
  margin-bottom: 5px;
}
