.piano-roll-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.octave-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.octave-button {
  padding: 4px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.octave-button:hover {
  background-color: #ff9d00;
}
.octave-button:focus {
  outline: none;
}
.piano-roll-modal-content {
  background: linear-gradient(135deg, #1a1a1a, #303030);
  padding: 20px;
  border-radius: 10px;
  max-width: 100%;
  width: 100%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.5);
}
.pianoroll-controls {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}
.piano-roll-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(135deg, #000000e9, #444444);
  padding: 10px;
  border-radius: 10px 10px 0 0;
  position: relative;
  box-shadow: 0 40 10px rgba(27, 86, 96, 0.5);
}

.piano-roll-title {
  margin: 0;
  text-align: center;
  font-size: 16px;
  flex-grow: 1;
  color: rgb(20, 200, 255);
  text-shadow: 0 0 5px rgb(3, 28, 27), 0 0 10px #0ff, 0 0 20px #0ff;
}

.close-button {
  position: absolute;
  right: 10px;
  background-color: transparent;
  color: #ff0;
  border: none;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  transition: color 0.3s, transform 0.3s;
}

.close-button:hover {
  color: #f44336;
  transform: scale(1.2);
}

.play-button-pianoroll {
  background: none;
  border: none;
  color: #0ff;
  font-size: 1.5rem;
  cursor: pointer;
  margin-right: 0px;
  transition: color 0.3s, transform 0.3s;
}

.play-button-pianoroll:hover {
  color: #ff0;
  transform: scale(1.2);
}
.pianoroll-controls select {
  background: #0ff;
  border: none;
  color: #000;
  font-size: 1rem;
  margin-right: 10px;
  padding: 5px;
  border-radius: 5px;
}

.pianoroll-controls select:hover {
  background: #ff0;
}
.bpm-controls {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.bpm-button {
  background: none;
  border: none;
  color: #0ff;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}

.bpm-button:hover {
  color: #ff0;
  transform: scale(1.2);
}

.bpm-display {
  margin: 0 0px;
  font-size: 15px;
  color: rgb(0, 255, 217);
  text-shadow: 0 0 2px #0ff, 0 0 10px #0ff, 0 0 20px #0ff;
}

.clear-button {
  background: none;
  border: 2px solid #0ff;
  color: #0ff;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 0px;
  padding: 2px 4px;
  border-radius: 5px;
  transition: color 0.3s, background-color 0.3s, transform 0.3s;
}

.clear-button:hover {
  color: #ff0;
  background-color: rgba(0, 255, 255, 0.2);
  transform: scale(1.2);
}
.save-pattern-button {
  background: none;
  border: none;
  color: #0ff;

  align-items: center;

  font-size: 1.5rem;
  cursor: pointer;
  margin: 0 10px;
  transition: color 0.3s, transform 0.3s;
}
.save-pattern-button-select {
  background: black;
  border: none;
  color: rgb(0, 255, 170);
  font-size: 1rem;
  cursor: pointer;
  margin-left: 10px;
  transition: color 0.3s, transform 0.3s;
}
.delete-pattern-button {
  background: none;
  border: none;
  color: #ff0000;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 0px;
  transition: color 0.3s, transform 0.3s;
}

.delete-pattern-button:hover {
  color: #ff4d4d;
  transform: scale(1.2);
}
.save-pattern-button:hover {
  color: #ff0;
  transform: scale(1.2);
}
.option-pattern {
  display: flex;
  width: 90%;
  justify-content: space-around;
  align-items: center;
}
.piano-roll-table-container {
  overflow: auto;
  margin-top: 2px;
  position: relative;
}

.piano-roll-table {
  width: 100%;
  border-collapse: collapse;
}

.piano-roll-table th,
.piano-roll-table td {
  border: 1px solid #444;
  padding: 5px; /* Reduced padding */
  text-align: center;
}

.piano-roll-table th {
  background: linear-gradient(135deg, #3a3a3a, #505050);
  color: #0ff;
  position: sticky;
  top: 0;
  z-index: 1;
  text-shadow: 0 0 2px #0ff, 0 0 10px #0ff, 0 0 20px #0ff;
}

.piano-roll-table .sticky-left {
  width: 60px; /* Fixed width for side keys */
  min-width: 60px; /* Ensure it doesn't shrink below this width */
  max-width: 60px; /* Ensure it doesn't grow beyond this width */
  position: sticky;
  left: 0;
  background: linear-gradient(135deg, #000000, #505050);
  z-index: 1;
  text-shadow: 0 0 2px #0ff, 0 0 10px #0ff, 0 0 20px #0ff;
}

.piano-roll-table .corner-box {
  z-index: 12;
  bottom: 0;
}
.piano-roll-table tfoot th {
  position: sticky;
  bottom: 0;
  z-index: 11;
}
.piano-roll-table td {
  border: 1px solid #000000;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
}

.piano-roll-table td:hover {
  background: rgba(255, 129, 2, 0.727);
  transform: scale(1);
}

.piano-roll-table td.active {
  background: rgba(0, 255, 0, 0.84);
}

.piano-roll-table td.playing {
  background: rgba(255, 0, 0, 0.6);
}

.volume-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 50px;
  height: 6px;
  align-items: center;
  background: rgb(133, 255, 255);
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;

  cursor: pointer;
}

.volume-slider:hover {
  opacity: 1;
}

.volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 13px;
  background: rgb(255, 213, 27);
  cursor: pointer;
}

.volume-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #ff0;
  cursor: pointer;
}
.stop-button2 {
  background-color: #ff4d4d; /* Red background color */
  color: white; /* White text color */
  border: none; /* No border */
  border-radius: 50%; /* Round button */
  width: 35px; /* Width of the button */
  height: 35px; /* Height of the button */
  /* Center vertically */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.stop-button2:hover {
  background-color: #8d0000; /* Darker red on hover */
}

.stop-button2:active {
  background-color: #cc0000; /* Even darker red when active (clicked) */
}
.metronome-beat {
  position: absolute;
  top: 0;
  left: 0;
  height: 1800px;
  width: 2px;
  background-color: #0ff;
  z-index: 2;
  transition: left 0.1s;
  box-shadow: 0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #0ff;
}

.metronome-beat.first-beat {
  background-color: #ff0;
  box-shadow: 0 0 10px #ff0, 0 0 20px #ff0, 0 0 30px #ff0;
}
.highlighted-column {
  background-color: rgba(
    184,
    129,
    129,
    0.3
  ); /* Light red background for the header cells */
}

.highlighted-cell {
  background-color: rgba(
    53,
    48,
    114,
    0.2
  ); /* Light red background for the grid cells */
}

.highlighted-cell.active {
  background-color: rgba(90, 71, 215, 0.256); /* Darker red for active cells */
}

.highlighted-cell.playing {
  background-color: rgba(
    255,
    27,
    27,
    0.879
  ); /* Even darker red for playing cells */
}
.natural-note td {
  height: 15px; /* Reduced height */
  background-color: #676666; /* Light background color */
}

/* Sharp notes (C#, D#, F#, G#, A#) */
.sharp-note td {
  height: 10px; /* Reduced height */
  background-color: #000000; /* Darker background color */
}
.current-bar {
  color: rgb(148, 83, 83);
  /* width: 102px; */
  background-color: rgb(124, 93, 208);
  /* font-size: ; */
  padding-bottom: 23px;
}
