.square {
  width: 100%; /* Full width within the grid */
  height: 0; /* Height is determined by padding to keep a square aspect ratio */
  padding-bottom: 100%; /* Maintain square ratio */
  display: flex;
  align-items: center;
  border: 1px solid rgb(96, 86, 86);
  justify-content: center;
  cursor: pointer;
  position: relative; /* Set position to relative for absolute positioning of pieces */
  overflow: hidden; /* Prevent any overflow */
}

.square.light {
  background: linear-gradient(
    25deg,
    #eee710,
    hsl(327, 100%, 51%)
  ); /* Light color for squares */
}

.square.dark {
  background: linear-gradient(
    25deg,
    #59b1c5,
    #5d00ff
  ); /* Dark color for squares */
}

.square.highlighted {
  background: linear-gradient(25deg, #fdba00, #ffff00);
  /* Highlight color for legal moves */
}

.square.in-check {
  background-color: #e90061; /* Highlight King in Check with red border */
}
