.sell-beats-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(125deg, #0f2542, #170c35, #000000ac, #0f3d42);

  color: #000000;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.sell-beats-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  text-align: left;
  font-weight: bold;
  color: #88dfff;
  margin-bottom: 5px;
}

input[type="text"],
input[type="file"] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
}

.onSubmitButton {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #564c56;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: rgb(131, 220, 217);
}

.onSubmitButton:hover {
  background-color: #ff2d2d;
  color: aqua;
}

.error-message {
  color: #ff0000;
  font-size: 14px;
  margin-top: 10px;
}
.progress-bar-container {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}

.progress-bar {
  height: 20px;
  background-color: #4caf50;
  width: 0;
  transition: width 0.4s ease;
}
