.kit-modal {
  position: fixed;
  z-index: 145;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.593);
}
/* .uploading {
  display: flex;
  flex-direction: row;
  align-items: center;
} */
.kit-modal-content {
  background-color: #0b0606;
  background-image: linear-gradient(145deg, #0c9292, #1a1a1a);
  color: #e0e0e0;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

.kit-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #ccc; /* Subtle contrast for the close button */
}

.kit-close:hover,
.kit-close:focus {
  color: #ff0000;
  text-decoration: none;
  cursor: pointer;
}

.kit-error {
  color: red;
  margin-top: 10px;
}
