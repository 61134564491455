.login-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.dot-selector-container {
  display: flex;
  justify-content: center;
  margin: 20px 0; /* Add some spacing */
}

.dot-selector-container label {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 10px; /* Horizontal spacing between dots */
}

.dot-selector-container input[type="radio"] {
  display: none; /* Hide the default radio button */
}

.dot-selector-container label::before {
  content: "";
  display: inline-block;
  width: 12px; /* Size of the dot */
  height: 12px;
  border-radius: 50%; /* Make it round */
  background-color: #aaa; /* Default color */
  margin-right: 5px; /* Spacing between dot and text */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.dot-selector-container input[type="radio"]:checked + label::before {
  background-color: #4da6ff; /* Color when selected */
}

.dot-selector-container label:hover::before {
  background-color: #82caff; /* Hover color */
}

.login-modal-content {
  background: linear-gradient(
    145deg,
    #33366b,
    #1a1a1a
  ); /* Dark metallic gradient */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5),
    inset 0 1px 2px rgba(255, 255, 255, 0.1),
    inset 0 -1px 2px rgba(0, 0, 0, 0.3);
  position: relative;
  width: 90%;
  max-width: 400px;
  text-align: center;
  color: #fff; /* Text color changed to white */
}

.login-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #ccc; /* Light grey to stand out a bit */
}

.login-modal-input {
  width: 100%;
  padding: 10px;
  margin: 10px -10px;
  border: 1px solid #444; /* Border color for inputs */
  border-radius: 4px;
  background: #201a1a; /* Darker input fields */
  color: #ddd; /* Lighter text color for contrast */
}

.login-modal-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background: linear-gradient(
    145deg,
    #555555,
    #2c2c2c
  ); /* Dark button gradient */
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background 0.3s ease;
}

.login-modal-button:hover {
  background: linear-gradient(
    145deg,
    #2c2c2c,
    #555555
  ); /* Hover effect inversion */
}

.login-modal-button:focus {
  outline: none;
}

.login-modal-text {
  display: block;
  margin-top: 20px;
  color: #aaa; /* Light grey text for less prominence */
}

.login-modal-link {
  background: none;
  color: #4da6ff; /* Link-like color */
  padding: 0;
  box-shadow: none;
  text-decoration: underline;
}

.login-modal-link:hover {
  background: none;
  color: #82caff; /* Lighter blue on hover */
}
.login-error {
  color: rgb(250, 147, 147);
  margin: 10px 0;
}
.google-login-button {
  background: linear-gradient(135deg, #000000, #283747, #ba1198);

  color: rgb(255, 232, 232);
  border: 1px solid #244870;
  padding: 6px;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 4px;
  width: 50%;
  margin: 0 auto;
  margin-top: 12px;
  justify-content: center;
}

.google-login-button img {
  width: 20px;
}
