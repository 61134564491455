.banner {
  width: 100%;
  margin-bottom: -10px;
  margin-top: 6px;
  justify-content: center;
  align-items: center;
}
.banner img {
  width: 70%;
  margin: 0 auto;
  /* Ensure images are responsive and cover the slide */
  max-height: 450px; /* Maintain aspect ratio */
  display: flex;
  padding-top: 1px;
  margin-bottom: -21px;
  /* Remove any extra space below the image */
}

/* Styling for the dots */
/* Positioning the dots below the carousel */
.slick-dots {
  bottom: 20px; /* Increase or decrease this value based on your design needs */
  position: absolute;
  width: 100%;
  list-style: none;
  text-align: center;
  padding: 0;
  margin-bottom: -30px;
}

.slick-dots li {
  display: inline-block;
  margin: 0 5px;
}

.slick-dots li button {
  font-size: 1px; /* Dot size, adjust if needed */
  line-height: 0px; /* Dot line height, adjust if needed */
  padding: 1px; /* Dot padding, adjust for larger click area */
  cursor: pointer;
  border: none;
  align-items: center;
  justify-content: center;
  background: none;
  color: #ff6f61; /* Vibrant coral color */
}

.slick-dots li button:before {
  content: "•"; /* Unicode character for the dot */
  font-size: 7px; /* Adjust the size of the dots */
  color: rgba(255, 253, 253, 0.75); /* Color of the dots */
}

.slick-dots li.slick-active button:before {
  color: #ff6f61; /* Active dot color */
}

.slick-dots li.slick-active button {
  color: #ff6f61; /* Active dot color */
}

/* Styling for the arrows */
.slick-arrow {
  width: 15%; /* Increase width */
  height: 89%; /* Adjust height as needed */
  z-index: 1;
  border-radius: 6px;
  background: linear-gradient(
    75deg,
    rgba(0, 0, 0, 0.205),
    #1070ac,
    #0f1f4e,
    #040411
  ); /* Dark background for arrows */
  color: #ff6f61; /* Vibrant color for arrows */
  transition: background-color 0.3s, color 0.3s;
}

.slick-arrow:hover {
  background: linear-gradient(
    75deg,
    #1070ac5b,
    rgba(252, 36, 36, 0.666),
    #d003a0d1,
    #0a0a55
  ); /* Vibrant color on hover */
  color: #ff1919;
}

.slick-prev {
  left: 0px; /* Adjust based on your layout */
}

.slick-next {
  right: 0px; /* Adjust based on your layout */
}

@media (max-width: 568px) {
  .banner img {
    padding-top: 6px;
  }
}
