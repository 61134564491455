/* Redirect.css */

.redirect-container {
  text-align: center;
  padding: 50px;
}

.download-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.download-link:hover {
  background-color: #2980b9;
}
