.fashion-container {
  width: 99%;
  background: #000000;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #ffffff;
}
.fashion-sidebar {
  width: 150px;
  min-width: 70px;
  height: 100%;
  background: linear-gradient(
    5deg,
    rgb(0, 0, 0),
    #000000,
    #281b68,
    #040411
  ); /* Dark shade for sidebar */

  color: white;
  padding: 0px 0px;
  border-radius: 0 12px 12px 0;
  top: 130px;
  margin: 0 auto;
  position: fixed; /* Fixed position */
  /* Allows scrolling */
}
.fashion-sidebar > p {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;

  color: #00f2ff;
  font-size: 15px;
  font-style: italic;
}
.fashion-content {
  background: linear-gradient(0deg, #000000, #19749f, #210a41, #000000);
}
.fashion-brand-button {
  display: flex;
  background: none;
  border-top: 1px solid rgba(250, 114, 202, 0.311);
  color: #ffffff;
  padding: 10px 0px;
  width: 100%;
  text-align: center;
  font-style: italic;
  margin: 0 auto;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  align-items: center;
  transition: background-color 0.3s;
}

.fashion-brand-button:hover {
  background: linear-gradient(225deg, #0b4b4f, #770560, #2515d6);
  color: #ffffff;
}
.active-brand {
  background: linear-gradient(225deg, #1d071c, #b41e73, #26090a);
  color: rgb(255, 255, 255) !important;
  font-weight: bold;
}
.fashion-content {
  margin-left: 150px; /* Give space for the sidebar */
}
.loader-fashion {
  margin-top: 20px;
}
.fashion-contain {
  margin-top: -20px;
}
.fashion-header button {
  background: none;
  cursor: pointer;
  border: none;
  margin-left: 140px;
}
.fashion-header button :hover {
  color: #04bfcc;
}
.brand-section {
  margin-bottom: 0px;
}

.brand-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  font-size: 34px;
  padding-top: 10px;
  padding-bottom: 23px;
  color: #e6ff02; /* Randomized */
  text-transform: uppercase;
  font-style: italic;
  font-family: sans-serif;
  margin-bottom: 0px;
  text-shadow: 0 3px 2px #000000, 0 0 3px #251f56, 0 0 6px #04bfcc; /* Randomized */
  text-align: center;
}

.brand-header > p {
  font-size: 7px;
  margin-right: 14px;
  text-transform: capitalize;
  color: #42f5f2; /* Randomized */
}

.fashion-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px;
  justify-content: space-around;
}

.fashion-item {
  background: linear-gradient(80deg, #000000, #524649);
  border-radius: 10px;
  overflow: hidden;
  justify-content: space-between;
  width: 180px;
  gap: 4px;

  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}
.fashion-item:hover {
  transform: scale(1.02);
}

.fashion-image {
  width: 100%;
  align-items: center;

  max-height: 173px;
  margin-top: 0;
  object-fit: contain;

  /* border-bottom: 2px solid #f59e42; Randomized */
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.fashion-image:hover {
  transform: scale(1.05);
}

.addCart-button-f {
  background-color: #ebd404; /* Randomized */
  color: #000000; /* Randomized */
  margin-top: 3px;
  margin-right: 6px;
  border: none;
  font-size: 14px;
  padding: 10px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-left: auto;
  margin-right: auto;
}

.addCart-button-f:hover {
  background-color: #b522d6; /* Randomized */
  color: #0c0c08; /* Randomized */
}

.addCart-button-f:disabled {
  background-color: #86767d; /* Randomized */
  cursor: not-allowed;
}

.fashion-details {
  display: flex;
  flex-direction: column;
  padding: 13px;
  text-align: center;
  text-transform: capitalize;
}

.fashion-details h3 {
  font-size: 13px;
  margin-bottom: 6px;
  color: #28fffb; /* Randomized */
  font-style: italic;
  text-transform: uppercase;
  font-weight: 600;
}

.fashion-details p {
  font-size: 14px;
  margin: 2px 0;
  color: #d7fffb; /* Randomized */
}

.fashion-details b {
  color: #e4ffe0; /* Randomized */
  font-size: 27px;
}

/* Modal styles */
.fashion-modal {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  align-items: center;
  justify-content: center;
}

.modal-content {
  max-width: 80%;
  max-height: 80%;
  background-color: #44475a;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.close {
  position: absolute;
  top: 20px;
  right: 35px;
  color: #f8f8f2;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #f54242; /* Randomized */
  text-decoration: none;
  cursor: pointer;
}

/* Stock Counter */

.quantity-input-container {
  display: flex;
  margin: auto;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.fashion-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto; /* Adjust as needed */
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  padding: 2px;
  margin-top: 10px;
}

.counter-button {
  background-color: #42b4ff;
  color: #000000;
  border: none;
  border-radius: 3px;
  align-items: center;
  padding: 2px 4px;
  cursor: pointer;
  font-size: 19px;
  line-height: 1;
}

.counter-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.counter-number {
  font-size: 14px;
  padding: 0 2px;
  width: 30px;
  text-align: center;
}

.fashion-item:hover .stock-counter {
  display: flex;
}
@media screen and (max-width: 558px) {
  .brand-header {
    font-size: 20px;
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .fashion-content {
    margin-left: 80px;
  }
  .fashion-sidebar {
    width: 80px;
    overflow-x: auto;
    top: 90px;
  }
  .fashion-sidebar > p {
    font-size: 6px;
  }
  .fashion-brand-button {
    font-size: 10px;
    justify-content: center;
  }
  .fashion-item {
    width: 100px;
    gap: 0px;
  }

  .fashion-image {
    height: auto;
    max-height: 100px;
    object-fit: contain;
  }

  .fashion-details h3 {
    font-size: 7px;
  }

  .fashion-details p {
    font-size: 8px;
  }

  .fashion-details b {
    font-size: 12px;
  }

  .addCart-button-f {
    font-size: 9px;
    /* padding: 10px 12px; */
    margin-right: 6px;
    height: 26px;
  }

  .quantity-counter button {
    font-size: 8px;
    width: 13px;
    height: 13px;
  }

  .stock-counter span {
    font-size: 7px;
  }
  .fashion-header button {
    margin-left: 0px;
  }
  .modal-content {
    max-width: 90%;
    max-height: 90%;
  }

  .close {
    font-size: 30px;
  }
}
