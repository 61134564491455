.navigation.hide {
  /* Hide navigation panel */
}
.navigation.show {
  /* Show navigation panel */
}
.navigation {
  width: 100%;

  z-index: 11;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background: linear-gradient(75deg, #08144d, #57259d, #064b80);

  opacity: 1;
  align-items: center;
  /* width: ; */
}

.nav-item {
  border-radius: 5px;
  padding: 9px 0px;

  font-weight: 500;
  text-align: center;
  width: 100%;
  align-items: center;
  text-transform: uppercase;
  /* font-style: italic; */
  /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
  color: rgb(238, 255, 252);
  justify-content: space-around;
  transition: background-color 0.3s ease; /* Adding smooth transition */
}

.nav-item:hover {
  background: linear-gradient(25deg, #f0b623, #ef0aff);
  color: #000000;
  /* font-weight: 800; */
}

.nav-link {
  color: #ffffff;
  /* background-color: #004f6e; */
  text-decoration: none;
  text-transform: uppercase;
  /* padding: 3px; */
  width: 100%;
  border-left: 1px solid #16828a;
  border-radius: 5px;
  text-align: center;
  align-items: center;
  margin-bottom: 0px;

  align-items: center;

  font-size: 12px;
}
/* Navigation styles for larger screens */
@media screen and (max-width: 568px) {
  .navigation {
    /* Start off-screen */
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #000000;
    /* Adjust this to start from the top of the viewport */
    /* opacity: 0.9; */
    transition: left 0.6s ease; /* Smooth transition when showing/hiding */

    border-radius: 10px;
  }

  .navigation.show {
    left: 0;
    width: 100%;
    min-width: 203px;
    /* Adjust this if you want the navigation to take full width */
    height: 85%;
    top: 50px;
    z-index: 34; /* Adjusted to take full height */
  }

  .navigation.hide {
    top: 50px;
    height: 85%;
    left: -600px;
    width: 100%;
    z-index: 34;
    /* Height adjustment is not necessary here since it's hidden */
  }

  .nav-link {
    color: #fff;

    text-decoration: none;
  }

  .nav-item {
    border-radius: 5px;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    font-size: 20px;
    margin: 10px 0px;
    text-align: center;
    font-style: italic;
    transition: background-color 0.5s ease; /* Adding smooth transition */
  }
}
