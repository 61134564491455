/* General Styles */

/* Purchased Container */
.purchased-container {
  width: 90%;
  margin: 0px auto;
  padding: 0 10px;
  background: linear-gradient(135deg, #000000, #283747, #000000);
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.8);
  color: #f0f8ff;
}

/* Purchased List */
.purchased-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  justify-content: space-evenly;
}

/* Purchased Item */
.purchased-item {
  color: #ffffff;
  background: linear-gradient(135deg, #232526, #0e151c);
  border-radius: 12px;
  padding: 10px;
  margin: 0 auto;
  margin-top: 13px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #00ffff;
}

.purchased-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
}

/* Audio Section */
.neon-audio-container {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.purchased-audio-player {
  width: 100%;
  max-width: 300px;
  border: none;
  border-radius: 42px;
  background: linear-gradient(135deg, #1c1c1c, #282828);
  color: #b6ff89;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8), 0 0 10px rgba(89, 255, 231, 0.6);
}

.purchased-audio-player::-webkit-media-controls-panel {
  background: #7189ff;
  border-radius: 10px;
  color: #c90000;
}

/* Album Art */
.purchased-album-art-image {
  max-width: 150px;
  border-radius: 12px;
  margin-bottom: 10px;
  border: 2px solid #00c9a7;
  box-shadow: 0 0 12px rgba(0, 201, 167, 0.7);
}

/* Tutorial Thumbnail Container */
.tutorial-thumbnail-container {
  position: relative;
  /* margin: 20px auto; */
  text-align: center;
  max-width: 480px;
}

.tutorial-videos-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
}

/* Thumbnail Wrapper */
.thumbnail-wrapper {
  position: relative;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

/* Thumbnail Image */
.thumbnail-image {
  display: block;
  width: 100%;
  height: 200px;

  object-fit: contain;
  border-radius: 12px;
  filter: brightness(85%);
  transition: filter 0.3s ease, transform 0.3s ease;
}

.thumbnail-image:hover {
  filter: brightness(100%);
  transform: scale(1.05);
}

/* Play Button Overlay */
.pur-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: radial-gradient(circle, #0f2027, #1abc9c);
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(26, 188, 156, 0.5);
  transition: transform 0.2s ease, background 0.3s ease, box-shadow 0.2s ease;
}

.pur-play-button:hover {
  transform: translate(-50%, -50%) scale(1.2);
  background: radial-gradient(circle, #1abc9c, #16a085);
  box-shadow: 0 0 20px #000000;
}

/* Video Player */
.purchased-video-player {
  width: 400px;
  max-width: 100%;
  border-radius: 12px;
  background-color: #1c1c1c;
  border: 2px solid #302a2f;
  box-shadow: 0 0 12px rgba(26, 188, 156, 0.5);
}
.purchased-details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #1a1a1a;
  padding: 5px;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 2px 6px rgba(0, 255, 255, 0.2);
  border: 1px solid #00d5ff76;
}
.purchased-details p {
  margin: 5px 0;
  color: #eaeaff;
  margin-right: 16px;
  font-size: 18px;
}

.purchased-details strong {
  font-weight: bold;
  margin-right: 6px;
  color: #33ddff;
}
/* Video Title */
.tutorial-thumbnail-container h5 {
  margin-top: 10px;
  font-size: 14px;
  color: #89ffe7;
  text-shadow: 0 0 5px rgba(89, 255, 231, 0.8);
}
.purchased-product-image {
  max-width: 150px;
  border-radius: 12px;
  margin-bottom: 10px;
  border: 2px solid #97c900;
  box-shadow: 0 0 12px rgba(0, 201, 167, 0.7);
}
/* Cart Item */
.pur-cartitem {
  display: flex;
  width: 100%;
  justify-content: space-around;
  color: #d74eba;
  font-weight: bold;
  font-size: 17px;
  text-transform: uppercase;
  margin: 0;
}
.pur-productName {
  margin: 0;
  margin-bottom: 15px;
  color: rgb(189, 97, 255);
  text-transform: capitalize;
  font-style: italic;
  font-size: 26px;
}
/* Responsive Design */
.cart-item {
  margin-bottom: 12px;
  background: linear-gradient(135deg, #000000, #283747, #000000);

  box-shadow: 0 4px 8px rgba(16, 15, 16, 0.5);
}
@media (max-width: 568px) {
  .tutorial-videos-container {
    flex-direction: column;
    align-items: center;
  }
  .thumbnail-image {
    height: 130px;
  }
  .purchased-list {
    flex-direction: column;
    align-items: center;
  }
  .purchased-details p {
    font-size: 10px;
  }
  .play-button {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
  .pur-productName {
    font-size: 15px;
  }
  .pur-cartitem {
    font-size: 10px;
  }
  .tutorial-thumbnail-container h5 {
    font-size: 10px;
  }
  .purchased-audio-player {
    max-width: 90%;
  }
}
