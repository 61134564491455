.gameover-modal {
  position: fixed; /* Fix the modal to the viewport */
  top: 0;
  left: 0;
  width: 100%; /* Cover the entire screen width */
  height: 100%; /* Cover the entire screen height */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex; /* Center the content */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  z-index: 1000; /* Ensure it's on top of other elements */
  transform: none; /* Reset any inherited transformations */
}

.gameover-modal-content {
  background-color: #fff; /* White background for the modal content */
  padding: 30px; /* Padding inside the modal */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Shadow for a subtle 3D effect */
  text-align: center; /* Centered text */
  max-width: 400px; /* Maximum width */
  width: 90%; /* Responsive width */
  transform: none; /* Ensure modal content is not rotated */
}

.gameover-modal-content h2 {
  margin-bottom: 20px; /* Space below the header */
  font-size: 24px; /* Large font size */
  color: #333; /* Dark text color */
}

.gameover-modal-content button {
  padding: 10px 20px; /* Button padding */
  background-color: #007bff; /* Button background color */
  color: white; /* Button text color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Font size */
  transition: background-color 0.3s; /* Smooth transition */
}

.gameover-modal-content button:hover {
  background-color: #0056b3; /* Darker background on hover */
}
