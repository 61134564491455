.services-container {
  color: #ffffff;
  padding: 0 20px;
}

.services-container h1 {
  text-align: center;
}

.service-topic {
  display: flex;
  flex-wrap: wrap;
}

.service-item {
  background: linear-gradient(135deg, #000000, #283747, #000000);
  padding: 20px;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.service-item h3 {
  margin-top: 0;
  color: #4edbeb;
  text-transform: uppercase;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.service-item p {
  margin-bottom: 0;
  font-style: italic;
}
.service-topic {
  display: flex;
  flex-direction: column;
  /* padding: 2px; */
  /* background: linear-gradient(135deg, #181924, #000000, #000000); */
  align-items: stretch;
}
