.learn-music-container {
  padding: 10px;

  color: #bdbcbc;
  font-family: "Arial", sans-serif;
  max-width: 1200px;
  width: 95%;
  margin: 0 auto;
}

.learn-music-header {
  /* font-size: 3em; */
  text-align: center;

  margin-top: -22px;
  margin-bottom: 12px;
}

.tutorial-title {
  font-size: 2rem; /* Adjust size as needed */
  font-weight: bold;
  font-style: italic;
  color: #42b5db; /* Main text color */
  text-transform: uppercase;
  text-shadow: 1px 1px 0 #0d1d1e, /* Shadow 1 */ 2px 2px 0 #000,
    /* Shadow 2 */ 3px 3px 0 #000, /* Shadow 3 */ 4px 4px 0 #000,
    /* Shadow 4 */ 5px 5px 0 #000, /* Shadow 5 */ 6px 6px 0 rgba(0, 0, 0, 0.5); /* Slight blur for depth */
  letter-spacing: 2px; /* Adjust spacing for clarity */
  background: linear-gradient(45deg, #000000, #0a140b); /* Gradient effect */
  -webkit-background-clip: text; /* Clip gradient to text */
  /* Make gradient visible */
}

.tutorial-title:hover {
  text-shadow: 1px 1px 1px rgb(77, 63, 82), 2px 2px 2px rgba(34, 49, 216, 0.905); /* Enhanced hover effect */
  transform: scale(1.05); /* Slightly enlarge on hover */
  transition: transform 0.2s ease-in-out;
}

.video-list {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adjust space between thumbnails as needed */
  justify-content: center; /* Center the thumbnails */
}

.tutorial-card {
  background: linear-gradient(126deg, #4e1094, #121a39, #0e2493);

  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 25px;
  transition: all 0.3s ease;
  display: flex;
  margin-bottom: 23px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.tutorial-card:hover {
  transform: scale(1.01);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.video-card {
  width: 100%;

  margin-bottom: 10px;
}
.tutorial-card > p {
  font-size: 16px;
  font-style: italic;
}
.video-carded {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  overflow-x: auto;
  align-items: center;
  /* justify-content: center; */
}
.thumbnail-container {
  position: relative;
  margin-bottom: 15px;
}

.thumbnail-lrnmus {
  width: 230px;
  height: 140px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  border: 1px solid #dddddd49;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  object-fit: contain;
}
.thumbnail-lrnmus.not-first-video {
  width: 170px; /* Reduced width for subsequent thumbnails */
  height: 100px;
  display: flex;
  flex-direction: column;
}
.thumbnail-lrnmus:hover {
  transform: scale(1.04);
}
.lrnmus-play-button {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: radial-gradient(circle, #0f2027, #1abc9c);
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(26, 188, 156, 0.5);
  transition: transform 0.2s ease, background 0.3s ease, box-shadow 0.2s ease;
}

.lrnmus-play-button:hover {
  transform: translate(-50%, -50%) scale(1.2);
  background: radial-gradient(circle, #1abc9c6a, #16a0847b);
  box-shadow: 0 0 20px #000000;
}
.video-name {
  font-size: 10px;
  margin-top: 5px;
  color: #fffafa;
  max-width: 150px;
}

.video-playing {
  width: 250px;

  height: 170px;
  border-radius: 8px;
  margin-bottom: 10px;
  object-fit: contain;
}

.video-details {
  width: 100%;
  padding: 10px 0;
  font-size: 15px;
  margin: 0 auto;
  color: #e6ffff;
  background: linear-gradient(135deg, #000000, #07121d, #000000);

  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.details-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  margin: 0 auto;

  font-size: 14px;
  color: #f2dfdf;
  flex-wrap: wrap;
}
.detail-tut {
  display: flex;

  align-items: center;
}
.detail-tut > p {
  color: #1aa9bc;
  margin-right: 5px;
}

.cart-button-tut {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 300px;
  margin-top: -10px;
}

.add-to-cart-button {
  background: radial-gradient(circle, #144a88, #0b8e8e);
  color: #fff;
  border: none;
  padding: 0px 7px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  transition: background-color 0.3s ease;
}

.add-to-cart-button:disabled {
  background: radial-gradient(circle, #393939, #747474);
  cursor: not-allowed;
}
.add-to-cart-button:hover {
  background: radial-gradient(circle, #320f3f, #750f57);
}
.video-cost {
  color: #ffffff;
  font-weight: 780;
  margin-right: 10px;
}
.centered-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.buy-now-modal {
  background: linear-gradient(234deg, rgb(35, 55, 183), #00000088, #a01677);

  border-radius: 10px;
  color: #ffffff;
  text-transform: capitalize;
  padding: 30px;
  width: 60%;

  align-items: center;

  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.buy-now-overlay {
  background-color: rgba(22, 21, 21, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  font-size: 12px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.buy-now-modal h2 {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 22px;
}

.add-cart-buynow-modal {
  background-color: #9309b3;
  color: #fbe6e6;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  display: block;
  margin: 0 auto;
  width: 60%;
}

.add-cart-buynow-modal:hover {
  background-color: #25b013;
}
.buy-modal-close {
  color: #de0000;
  font-size: 28px; /* Increased */
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
}

.buy-modal-close:hover,
.buy-modal-close:focus {
  color: #ff5555;
  text-decoration: none;
  cursor: pointer;
}
.buy-now-header {
  display: flex;
  justify-content: space-between;
}
.info-btn-lm {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  margin-top: 10px;
}

.info-message-lm {
  position: absolute;
  background-color: #202020;
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  font-size: 12px;
  /* Adjust based on your layout */
  bottom: 0px;
  right: 15px; /* Adjust based on your layout */
  width: 300px;
  z-index: 10122;
}

@media (max-width: 568px) {
  .video-list {
    flex-direction: column;
    gap: 10px; /* Adjust space for vertical stacking */
  }
  .tutorial-title {
    font-size: 1em;
  }
  .details-row {
    display: flex;
    flex-direction: column;
    font-size: 11px;

    width: 100%;
    align-items: flex-start;
  }
  .detail-tut {
    margin: -6px;
    margin-left: 10px;
  }
  .video-carded {
    gap: 14px;
    justify-content: space-around;
  }
  .tutorial-card > p {
    font-size: 10px;
    font-style: italic;
  }
  .thumbnail-lrnmus {
    width: 215px;
    height: 120px;
  }
  .thumbnail-lrnmus.not-first-video {
    width: 100px; /* Reduced width for subsequent thumbnails */
    height: 60px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
  }
  .video-playing {
    width: 240px;
  }
  .video-name {
    font-size: 9px;
    max-width: 100px;
    margin: 0 auto;
    margin-top: 5px;
  }

  .learn-music-container {
    margin-top: -40px;
  }
  .learn-music-header {
    margin-top: 0px;
  }
  .video-details {
    font-size: 10px;
    margin: 0;
  }
  .cart-button-tut {
    flex-direction: column;
  }
}
