.confirm-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(1, 1, 1, 0.5); /* Semi-transparent background */
}

.confirm-modal-content {
  padding: 20px;
  background: linear-gradient(80deg, #062320, #2a384a, #471661, #0a2728);
  border: 3px solid rgb(59, 107, 117);
  border-radius: 5px;
  display: flex;
  margin-top: 43px;
  flex-direction: column;
  align-items: center;
}

.confirm-modal-header {
  font-size: 20px;
  font-weight: bold;
  color: #f86c6c;
  margin-bottom: 20px; /* Spacing between header and buttons */
}

.confirm-modal-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff; /* Bootstrap blue */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  outline: none;
  transition: background-color 0.3s ease;
}

.confirm-modal-button:hover {
  background-color: #b3007a; /* Darker blue on hover */
}

.confirm-modal-button:last-child {
  margin-right: 0; /* No margin on the right for the last button */
}
