.checkout-container {
  padding: 20px;
  background: linear-gradient(135deg, #21183c, #000000);
  margin-top: 20px;
}

.form-container-checkout {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(135deg, #121115, #0b3337);
  border-radius: 10px;
  color: #f7f7f7;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
.checkout-product-list {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.title-checkout {
  margin: 0 auto;
}
.cart-title-checkout,
.payment-title-checkout,
.address-title-checkout {
  font-size: 20px;
  margin-bottom: 10px;
  font-style: italic;
  color: #c2b8c4;
  text-shadow: 0 0 3px #000000, 0 0 5px #17141b;
}

.cart-section-title-checkout {
  display: flex;
  align-items: flex-start;
  font-size: 20px;
  margin-top: 4px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin-bottom: 0px;
  color: #ff91b0;
}

.cart-item-list-checkout {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.cart-item-checkout {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #555;
}

.product-name-checkout {
  color: #05ffd5;
}

.product-cost-checkout {
  color: rgb(160, 255, 59);
  display: flex;
  justify-content: flex-end;
  /* align-items: end; */
  font-weight: bold;
}

.total-price-checkout {
  font-size: 18px;
  color: #f7ffc7;
  text-align: right;
  margin-top: 10px;
}
.select-field-checkout {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  background-color: #0632b7;
  border: none;
  border-radius: 5px;
  color: #191010;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.select-field-checkout:focus {
  background-color: #0030c0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
}

.form-label-checkout {
  font-size: 16px;
  margin-bottom: 5px;
  text-align: justify;
  margin-left: 4px;

  color: #00ffbb;
  text-shadow: 0 0 3px #000000, 0 0 5px #bd93f9;
}

.input-field-checkout {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  background: linear-gradient(135deg, #44475a, #282a36);
  border: none;
  border-radius: 5px;
  color: #ffecec;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.input-field-checkout:focus {
  background-color: #b7bbc7;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
}

.select-field-checkout {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  background: linear-gradient(135deg, #44475a, #282a36);
  border: none;
  border-radius: 5px;
  color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.select-field-checkout:focus {
  background-color: #6272a4;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
}

.error-message-checkout {
  margin-top: -16px;
  margin-bottom: 10px;
  color: #ff5555;
}
.submit-button-checkout {
  width: 50%;
  padding: 12px;
  background: linear-gradient(135deg, #000000, #6e05ff);
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  transition: background 0.3s, box-shadow 0.3s;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 0 0 10px #7300ff;
}

.submit-button-checkout:hover {
  background: linear-gradient(135deg, #00ffcc, #ff073a);
  box-shadow: 0 0 15px #00ffcc, 0 0 25px #ff073a;
}

/* Coupon Section */
.coupon-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin-bottom: 15px; */
}

.input-field-checkout {
  width: 100%;
  padding: 8px;
  margin: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.coupon-typer {
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 50%;
  margin: 0 auto;
}
.apply-coupon-button {
  background: linear-gradient(135deg, #1a683d, #740838);

  color: rgb(255, 240, 240);
  padding: 4px 3px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
  width: 170px;
}

.apply-coupon-button:hover {
  background: linear-gradient(135deg, #3fb73d, #ead28c);

  color: #0030c0;
}

/* Applied Coupons Container */
.applied-coupons {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.applied-coupon-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #2e312e;
  color: #00ffbb;
  padding: 8px;
  width: fit-content;
  margin-right: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 4px;
  margin-top: 5px;
  font-size: 14px;
}

.applied-coupon-item span {
  font-weight: bold;
  color: #fbfffe;
}

.applied-coupon-item button {
  background: #e74c3c;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.applied-coupon-item button:hover {
  background: #c0392b;
}

.discount-applied {
  color: rgb(38, 224, 35);
  font-weight: bold;
}

.final-price-checkout {
  font-size: 19px;
  font-weight: bold;
  font-style: initial;
  color: #fffdc9; /* Red to highlight final price */
}
.previous-cost {
  text-decoration: line-through;
  color: red;
  margin-right: 5px;
  font-size: 15px;
}

.discounted-cost {
  font-weight: bold;
  color: rgb(160, 255, 59);
  /* font-size: 13px; */
}
@media screen and (max-width: 558px) {
  .checkout-container {
    margin-top: -30px;
  }
  .product-name-checkout {
    font-size: 10px; /* Already specified */
    text-align: left;
  }
  .product-cost-checkout {
    font-size: 12px; /* Already specified */
    width: 83px;
  }
  .total-price-checkout {
    font-size: 13px; /* Already specified */
  }
  .title-checkout,
  .cart-title-checkout,
  .payment-title-checkout,
  .address-title-checkout {
    font-size: 20px; /* Reduced from 24px */
  }
  .cart-section-title-checkout {
    font-size: 10px;
    display: flex;
    align-items: flex-start; /* Reduced from 20px */
  }
  .form-label-checkout {
    font-size: 14px; /* Reduced from 16px */
  }
  .submit-button-checkout {
    font-size: 12px; /* Add this to reduce font size for the button */
  }
  .apply-coupon-button {
    font-size: 10px;
  }
  .final-price-checkout {
    margin-top: 23px;
    font-size: 15px;
  }
  .coupon-typer {
    width: 80%;
  }
}
