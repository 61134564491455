.promotion-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensure it appears above other elements */
}

.promotion-modal h2 {
  margin-bottom: 10px;
  text-align: center;
  color: #333;
}

.promotion-options {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.promotion-button {
  width: 50px;
  height: 50px;
  font-size: 24px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.promotion-button:hover {
  background-color: #ddd;
}

.promotion-button.white {
  color: #fff;
  background-color: #6b7aff; /* Color for white pieces */
}

.promotion-button.black {
  color: #000;
  background-color: #ffb347; /* Color for black pieces */
}

.promotion-button:active {
  transform: scale(0.95);
}
